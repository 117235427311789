import {
  IonContent,
  IonHeader,
  IonSlides,
  IonToolbar,
  IonTitle,
} from "@ionic/react";
import {
  HelloPatakara,
  HowToUse,
  RequestForUse1,
} from "../components/guideSlide/index";

const Guide = ({ loginWithRedirect }) => {
  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>アプリを始める前に</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonSlides pager={true} style={{ height: "95%" }}>
        <HelloPatakara />
        <HowToUse />
        <RequestForUse1 loginWithRedirect={loginWithRedirect} />
      </IonSlides>
    </IonContent>
  );
};

export default Guide;
