import { IonText } from "@ionic/react";

export default function PageTitle({ title, subtitle }) {
  return (
    <div className="page-title">
      <IonText color="primary" className="ion-text-center">
        {title && <h2 className="has-underline">{title}</h2>}
        {subtitle && <h3>{subtitle}</h3>}
      </IonText>
    </div>
  );
}
