import {
  IonItem,
  IonLabel,
  IonInput,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonRadioGroup,
  IonRadio,
  IonAlert,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "./ProfileRegistration.css";
import { registerUserInfo, putRegisterUserInfo } from "../services/api";
import { useAuth0 } from "@auth0/auth0-react";
import DateOfBirthPicker from "./DateOfBirthPicker";

function ProfileRegistration({ setHasUserInfo, userInfo, setUserInfo }) {
  const { getAccessTokenSilently } = useAuth0();
  const [name, setName] = useState("");
  const [gender, setGender] = useState(null);
  const [isDownSyndrome, setIsDownSyndrome] = useState(null);
  const [guardianName, setGuardianName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [dialogMsg, setDialogMsg] = useState("");
  const [birthDay, setBirthDay] = useState(null);

  const telRegexp = /^0\d{9,10}$/;
  const emailRegexp =
    /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

  useEffect(() => {
    (async () => {
      if (userInfo) {
        setName(userInfo.name);
        setBirthDay(userInfo.date_of_birth);
        setGender(userInfo.gender);
        setIsDownSyndrome(userInfo.down_syndrome);
        setGuardianName(userInfo.name_of_family);
        setEmail(userInfo.email_of_family);
        setTel(userInfo.tel_of_family);
      }
    })();
  }, [userInfo]);

  function filledOut() {
    if (name === "") {
      return false;
    }
    if (birthDay === null) {
      return false;
    }
    if (gender === null) {
      return false;
    }
    if (isDownSyndrome === null) {
      return false;
    }
    if (guardianName === "") {
      return false;
    }
    if (email === "") {
      return false;
    }
    if (tel === "") {
      return false;
    }
    return true;
  }

  function hasUpdatedItem() {
    if (name !== userInfo.name) {
      return true;
    }
    if (birthDay !== userInfo.date_of_birth) {
      return true;
    }
    if (gender !== userInfo.gender) {
      return true;
    }
    if (isDownSyndrome !== userInfo.down_syndrome) {
      return true;
    }
    if (guardianName !== userInfo.name_of_family) {
      return true;
    }
    if (email !== userInfo.email_of_family) {
      return true;
    }
    if (tel !== userInfo.tel_of_family) {
      return true;
    }
    return false;
  }

  async function register() {
    const filledUserInfo = {
      name,
      date_of_birth: birthDay,
      gender,
      isDownSyndrome,
      guardianName,
      email,
      tel,
    };
    try {
      //TODO:本当は入力したら赤文字とかで横に出るようにしたい
      if (!telRegexp.test(tel)) {
        const e = new Error("IncorrectTelephonNumber");
        e.name = "IncorrectTelephonNumberError";
        throw e;
      }
      if (!emailRegexp.test(email)) {
        const e = new Error("IncorrectEamil");
        e.name = "IncorrectEamilError";
        throw e;
      }

      if (userInfo === null) {
        await registerUserInfo(
          JSON.stringify(filledUserInfo),
          getAccessTokenSilently
        );
      } else {
        const updatedInfo = await putRegisterUserInfo(
          JSON.stringify(filledUserInfo),
          getAccessTokenSilently
        );
        setUserInfo(updatedInfo);
      }
    } catch (e) {
      if (e.name === "IncorrectTelephonNumberError") {
        setDialogMsg(
          "電話番号が正しくありません。正しい電話番号を入力してください"
        );
      } else if (e.name === "IncorrectEamilError") {
        setDialogMsg(
          "メールアドレスが正しくありません。正しいアドレスを入力してください"
        );
      } else {
        setDialogMsg("エラーが発生しました。もう一度やり直してください。");
      }
      setShowErrorDialog(true);
      return;
    }

    if (userInfo === null) {
      setHasUserInfo(true);
    } else {
      setHasUserInfo(false);
    }
  }

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar className="Header">
          <IonTitle>プロフィール入力</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className="profile_content">
        <IonCard>
          <div>
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                プロフィール
              </IonCardTitle>
            </IonCardHeader>

            <div className="consent_form" style={{ marginBottom: "15px" }}>
              <div className="group">
                <div>ご利用者</div>
                <IonItem>
                  <IonLabel>お名前</IonLabel>
                  <IonInput
                    placeholder="お名前"
                    value={name}
                    onIonChange={(e) => setName(e.target.value)}
                  ></IonInput>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>生年月日</IonLabel>
                </IonItem>
                {/* <div className="birthday">
                    <div className="year">
                      <IonInput
                        placeholder="2000"
                        value={year}
                        maxlength={4}
                        onIonChange={(e) => setYear(e.target.value)}
                      ></IonInput>
                      <span>年</span>
                    </div>
                    <div className="month">
                      <IonInput
                        placeholder="1"
                        value={month}
                        maxlength="2"
                        onIonChange={(e) => setMonth(e.target.value)}
                      ></IonInput>
                      <span>月</span>
                    </div>
                    <div className="date">
                      <IonInput
                        placeholder="1"
                        maxlength="2"
                        value={dateOfBirth}
                        onIonChange={(e) => setDateOfBirth(e.target.value)}
                      ></IonInput>
                      <span>日</span>
                    </div>
                  </div> */}

                <IonItem>
                  <DateOfBirthPicker
                    birthDay={birthDay}
                    setBirthDay={setBirthDay}
                  />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>性別</IonLabel>
                </IonItem>
                <IonItem>
                  <IonRadioGroup
                    className="gender"
                    value={gender}
                    style={{ flexDirection: "column" }}
                  >
                    <IonItem lines="none">
                      <IonLabel className="radio_label">男</IonLabel>
                      <IonRadio
                        slot="start"
                        value="male"
                        onClick={(e) => {
                          setGender(e.target.value);
                        }}
                      ></IonRadio>
                    </IonItem>
                    <IonItem lines="none">
                      <IonLabel className="radio_label">女</IonLabel>
                      <IonRadio
                        slot="start"
                        value="female"
                        onClick={(e) => {
                          setGender(e.target.value);
                        }}
                      ></IonRadio>
                    </IonItem>
                    <IonItem lines="none">
                      <IonLabel className="radio_label">無回答</IonLabel>
                      <IonRadio
                        slot="start"
                        value="unanswered"
                        onClick={(e) => {
                          setGender(e.target.value);
                        }}
                      ></IonRadio>
                    </IonItem>
                  </IonRadioGroup>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>属性</IonLabel>
                </IonItem>
                <IonItem>
                  <IonRadioGroup value={isDownSyndrome}>
                    <IonItem lines="none">
                      <IonLabel className="radio_label">ダウン症候群</IonLabel>
                      <IonRadio
                        slot="start"
                        value={true}
                        onClick={(e) => {
                          setIsDownSyndrome(e.target.value);
                        }}
                      ></IonRadio>
                    </IonItem>
                    <IonItem lines="none">
                      <IonLabel className="radio_label">それ以外</IonLabel>
                      <IonRadio
                        slot="start"
                        value={false}
                        onClick={(e) => {
                          setIsDownSyndrome(e.target.value);
                        }}
                      ></IonRadio>
                    </IonItem>
                  </IonRadioGroup>
                </IonItem>
              </div>

              <div className="group">
                <div>ご家族・代諾者</div>
                <IonItem>
                  <IonLabel>お名前</IonLabel>
                  <IonInput
                    placeholder="お名前"
                    value={guardianName}
                    onIonChange={(e) => setGuardianName(e.target.value)}
                  ></IonInput>
                </IonItem>

                <div>
                  <IonItem>
                    <IonLabel>電話番号</IonLabel>
                    <IonInput
                      type="tel"
                      placeholder="00088888888"
                      value={tel}
                      onIonChange={(e) => setTel(e.target.value)}
                    ></IonInput>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>メールアドレス</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonInput
                      placeholder="email@domain.com"
                      value={email}
                      onIonChange={(e) => setEmail(e.target.value)}
                    ></IonInput>
                  </IonItem>
                </div>
              </div>
            </div>
          </div>
        </IonCard>

        <div
          style={{ display: "flex", justifyContent: "center", margin: "10px" }}
        >
          <IonButton
            onClick={register}
            color="primary"
            style={{
              width: "240px",
            }}
            disabled={userInfo ? !hasUpdatedItem() : !filledOut()}
          >
            登録
          </IonButton>
        </div>
        {userInfo !== null && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px",
            }}
          >
            <IonButton
              onClick={() => {
                setHasUserInfo(false);
              }}
              color="primary"
              style={{
                width: "240px",
              }}
              disabled={!filledOut()}
            >
              キャンセル
            </IonButton>
          </div>
        )}
      </div>
      <IonAlert
        isOpen={showErrorDialog}
        onDidDismiss={() => setShowErrorDialog(false)}
        header="エラー"
        message={dialogMsg}
        buttons={["OK"]}
      />
    </IonContent>
  );
}

export default ProfileRegistration;
