import {
  IonApp,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import HomePage from "./pages/Home";
import Task from "./pages/Task";
import Recording from "./pages/Recording";
import Record from "./pages/Record";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect, Route } from "react-router-dom";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Questionnaires from "./pages/Questionnaires";
import SpeechIntelligibility from "./components/questionnaires/SpeechIntelligibility";
import EatingAndSwallowing from "./components/questionnaires/EatingAndSwallowing";
import QuestionnairesStatus from "./components/questionnaires/QuestionnairesStatus";
import EatingAndSwallowingAns from "./components/questionnaires/EatingAndSwallowingAns";
import SpeechIntelligibilityAns from "./components/questionnaires/SpeechIntelligibilityAns";

function App() {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <IonMenu contentId="main">
            <IonHeader>
              <IonToolbar>
                <IonTitle>メニュー</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonList>
                <IonItem
                  onClick={() => {
                    logout({ returnTo: window.location.origin });
                  }}
                >
                  ログアウト
                </IonItem>
                <IonItem routerLink={`/profile`}>プロフィール</IonItem>
                <IonItem routerLink={`/questionnaires`}>アンケート</IonItem>
              </IonList>
            </IonContent>
          </IonMenu>
          <IonRouterOutlet id="main">
            <Route
              exact
              path="/home"
              render={(props) => {
                return isAuthenticated ? <HomePage {...props} /> : <Login />;
              }}
            />

            <Route path="/task/:folderId" component={Task} exact={true} />
            <Route
              path="/task/:folderId/record"
              component={Recording}
              exact={true}
            />
            <Route
              path="/task/:folderId/record/:record_id"
              component={Record}
              exact={true}
            />
            <Route path="/profile" component={Profile} exact={true} />
            <Route
              path="/questionnaires"
              component={Questionnaires}
              exact={true}
            />
            <Route
              path="/questionnaires/status/:questionnairesKey"
              component={QuestionnairesStatus}
              exact={true}
            />
            <Route
              path="/questionnaires/speech_intelligibility"
              component={SpeechIntelligibility}
              exact={true}
            />
            <Route
              path="/questionnaires/eating_and_swallowing"
              component={EatingAndSwallowing}
              exact={true}
            />
            <Route
              path="/questionnaires/speech_intelligibility/answer/:id"
              component={SpeechIntelligibilityAns}
              exact={true}
            />
            <Route
              path="/questionnaires/eating_and_swallowing/answer/:id"
              component={EatingAndSwallowingAns}
              exact={true}
            />
            <Redirect exact from="/" to="/home" />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
