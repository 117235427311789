import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonMenuButton,
  IonContent,
  IonItem,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonRadio,
  IonRadioGroup,
  useIonViewWillEnter,
} from "@ionic/react";
import { useState } from "react";
import "./Questionnaires.css";
import { useAuth0 } from "@auth0/auth0-react";
import { getAnswer } from "../../services/api";
import { QuestionnairesKey } from "../../assets/Common";
import { convertDate } from "../../assets/Common";
import { useParams } from "react-router";
import Loading from "../Loading";
import "./QuestionnairesAns.css";

const rankDict = {
  1: "良くわかる",
  2: "時々わからない語がある程度",
  3: "聞き手が話題を知っているとどうやらわかる程度",
  4: "時々わかる語があるという程度",
  5: "全く理解不能",
};

function Content({ data }) {
  return (
    <div>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className="ion-text-center" color="secondary">
            会話明瞭度のアンケート
          </IonCardTitle>
        </IonCardHeader>
        <IonItem>
          <IonLabel>判定日</IonLabel>
          {convertDate(new Date(data.date))}
        </IonItem>
        <IonItem>
          <IonLabel>判定者</IonLabel>
          <IonRadioGroup className="gender">
            <IonItem lines="none">
              <IonLabel className="radio_label" style={{ opacity: "1" }}>
                家族
              </IonLabel>
              <IonRadio
                slot="start"
                checked={data.isFamily}
                disabled={true}
                className="disabled"
              ></IonRadio>
            </IonItem>
            <IonItem lines="none">
              <IonLabel className="radio_label" style={{ opacity: "1" }}>
                家族以外
              </IonLabel>
              <IonRadio
                slot="start"
                checked={!data.isFamily}
                disabled={true}
                className="disabled"
              ></IonRadio>
            </IonItem>
          </IonRadioGroup>
        </IonItem>
        <IonItem lines="none">
          <IonLabel>会話の明瞭さ</IonLabel>
        </IonItem>
        <IonItem>
          <div>
            <IonRadioGroup>
              {Object.entries(rankDict).map(([rank, txt]) => {
                return (
                  <IonItem lines="none" key={rank}>
                    <IonLabel
                      className="ion-text-wrap questionnaires_label"
                      style={{ opacity: "1" }}
                    >
                      {txt}
                    </IonLabel>
                    <IonRadio
                      style={{ marginRight: "8px" }}
                      slot="start"
                      checked={data.ques1 === rank}
                      disabled={true}
                      className="disabled"
                    ></IonRadio>
                  </IonItem>
                );
              })}
            </IonRadioGroup>
          </div>
        </IonItem>
      </IonCard>
    </div>
  );
}

export default function SpeechIntelligibilityAns() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useIonViewWillEnter(() => {
    const getAns = async () => {
      const ans = await getAnswer(id, getAccessTokenSilently);
      setData(JSON.parse(ans["answer"]));
    };
    getAns();
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="Header">
          <IonTitle>回答</IonTitle>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/questionnaires/status/${QuestionnairesKey.SPEECH_INTELLIGIBILITY}`}
              text="状態"
            />
          </IonButtons>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data === null ? <Loading /> : <Content data={data} />}
      </IonContent>
    </IonPage>
  );
}
