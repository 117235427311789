import "./Record.css";
import folders from "../assets/task.json";

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { getRecord } from "../services/api";
import { convertDate } from "../services/date";
import Loading from "../components/Loading";
import PageTitle from "../components/PageTitle";
import ResultDisplay from "../components/ResultDisplay";

function Content({ folder, record }) {
  if (record == null || folder == null) {
    return <Loading />;
  }

  return (
    <>
      <PageTitle
        title={`「${folder.name}」上手に言えたかな？`}
        subtitle={convertDate(record.created)}
      />
      <ResultDisplay
        sentenceData={record.juri_data}
        crispness={record.crispness_data}
        watsonData={record.wataru_data}
        amiData={record.ami_data}
        gouData={record.gou_data}
        folder={folder}
      />
    </>
  );
}

export default function Record() {
  const { folderId, record_id } = useParams();
  const [record, setRecord] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useIonViewWillEnter(async () => {
    const record = await getRecord(record_id, getAccessTokenSilently);
    setRecord(record);
  });

  const folder = useMemo(() => {
    for (const d of folders) {
      if (d.id === Number(folderId)) {
        return d;
      }
    }
    return null;
  }, [folderId]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="Header">
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/task/${folderId}`} text="一覧" />
          </IonButtons>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Content record={record} folder={folder} />
      </IonContent>
    </IonPage>
  );
}
