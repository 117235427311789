import { useState } from "react";
import {
  IonContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
} from "@ionic/react";
import ParentTermsOfServiceDetail from "../components/termsOfService/ParentTermsOfServiceDetail";
import ChildTermsOfServiceDetail from "../components/termsOfService/ChildTermsOfServiceDetail";

export function isAgreed() {
  if ("agreed" in localStorage) {
    return true;
  } else {
    return false;
  }
}

export default function TermsOfService({ loginWithRedirect }) {
  function setAgreedToLocalStrage() {
    if (!isAgreed()) {
      localStorage.setItem("agreed", "true");
    }
  }

  function allAggreed() {
    if (childAgreed && parentAgreed) {
      return true;
    }
    return false;
  }

  const [parentAgreed, setParentAgreed] = useState(false);
  const [childAgreed, setChileAgreed] = useState(false);

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>利用規約</IonTitle>
        </IonToolbar>
      </IonHeader>

      <ChildTermsOfServiceDetail
        agreed={childAgreed}
        setAgreed={setChileAgreed}
      />

      <ParentTermsOfServiceDetail
        agreed={parentAgreed}
        setAgreed={setParentAgreed}
      />

      <div
        style={{ display: "flex", justifyContent: "center", margin: "10px" }}
      >
        <IonButton
          onClick={() => {
            setAgreedToLocalStrage();
            // TODO:そのままAuthのログインがいいのか、一回ホームに戻ってログインするボタンを押させてからAuthのログインに行くか
            loginWithRedirect();
          }}
          color="primary"
          style={{
            width: "240px",
          }}
          disabled={!allAggreed()}
        >
          次へ
        </IonButton>
      </div>
    </IonContent>
  );
}
