import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonMenuButton,
  IonContent,
  IonItem,
  IonList,
  IonLabel,
  IonButton,
  useIonViewWillEnter,
} from "@ionic/react";
import { convertDate } from "../../services/date";
import { useParams } from "react-router";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getQuestionnaires, getAnswers } from "../../services/api";
import { QuestionnairesKey } from "../../assets/Common";
import Loading from "../Loading";
import PageTitle from "../PageTitle";

function Content({ questionnairesInfo, answerList }) {
  return (
    <div>
      <div style={{ padding: "0 12px" }}>
        <PageTitle title={`${questionnairesInfo.name}に関するアンケート`} />
      </div>

      <div>
        <IonButton
          className="recording-button"
          expand="block"
          routerLink={`/questionnaires/${questionnairesInfo.key}`}
        >
          回答する
        </IonButton>
        {questionnairesInfo.key === QuestionnairesKey.EATING_AND_SWALLOWING && (
          <p style={{ padding: "0 16px", textAlign: "center" }}>
            ※3~6ヶ月ごとの回答が推奨されています
          </p>
        )}
      </div>
      <div>
        <hr />
        <h3 style={{ textAlign: "center", color: "#606060" }}> 回答履歴</h3>
        {answerList.length === 0 ? (
          <div style={{ textAlign: "center", paddingTop: "12px" }}>
            回答がありません
          </div>
        ) : (
          <IonList style={{ marginTop: "20px" }}>
            {answerList.map((ans, index) => {
              return (
                <IonItem
                  lines="none"
                  routerLink={`/questionnaires/${questionnairesInfo.key}/answer/${ans.id}`}
                  key={index}
                  className="Past"
                  detail={false}
                  style={{ textAlign: "center" }}
                >
                  <IonLabel>{convertDate(new Date(ans.created))}</IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        )}
      </div>
    </div>
  );
}

export default function QuestionnairesStatus() {
  const { questionnairesKey } = useParams();
  const [questionnairesInfo, setQuestionnairesInfo] = useState(null);
  const [answerList, setAnswerList] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useIonViewWillEnter(() => {
    const getQuestionnairesName = async () => {
      const questionnaires = await getQuestionnaires(
        questionnairesKey,
        getAccessTokenSilently
      );
      setQuestionnairesInfo(questionnaires);
    };
    const getQuestionnairesAnswers = async () => {
      const ans = await getAnswers(questionnairesKey, getAccessTokenSilently);
      const sortedAns = ans.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );
      setAnswerList(sortedAns);
    };
    getQuestionnairesName();
    getQuestionnairesAnswers();
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="Header">
          <IonTitle>アンケート状況</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/questionnaires`} text="一覧" />
          </IonButtons>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {questionnairesInfo === null ? (
          <Loading />
        ) : (
          <Content
            questionnairesInfo={questionnairesInfo}
            answerList={answerList}
          />
        )}
      </IonContent>
    </IonPage>
  );
}
