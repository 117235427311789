import React from "react";
import { IonAlert } from "@ionic/react";

export default function ErrorPopup({ subHeader, msg }) {
  return (
    <IonAlert
      isOpen={true}
      header="エラー"
      subHeader={subHeader}
      message={msg}
      buttons={["閉じる"]}
    />
  );
}
