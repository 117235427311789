import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonMenuButton,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonTitle,
  IonItem,
  IonLabel,
  useIonViewWillEnter,
  IonModal,
  IonLoading,
  IonButton,
} from "@ionic/react";
import { useState } from "react";
import { getUserInfo } from "../services/api";
import { useAuth0 } from "@auth0/auth0-react";
import ProfileRegistration from "../components/ProfileRegistration";
import { convertDate } from "../assets/Common";
import "./Profile.css";

const genderDict = { female: "女", male: "男", unanswered: "無回答" };

function Content() {
  const { getAccessTokenSilently } = useAuth0();
  const [profile, setProfile] = useState({});
  const [edit, setEdit] = useState(false);

  useIonViewWillEnter(async () => {
    const user_info = await getUserInfo(getAccessTokenSilently);
    setProfile(user_info);
  });

  if (!profile) {
    return <div>登録されていません</div>;
  }

  if (Object.keys(profile).length === 0) {
    <IonLoading
      cssClass="my-custom-class"
      isOpen={Object.keys(profile).length === 0}
      message={"読み込み中"}
    />;
  }

  return (
    <div>
      {Object.keys(profile).length === 0 ? (
        <IonLoading
          cssClass="my-custom-class"
          isOpen={Object.keys(profile).length === 0}
          message={"読み込み中"}
        />
      ) : (
        <div>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle className="ion-text-center" color="secondary">
                利用者
              </IonCardTitle>
            </IonCardHeader>
            <IonItem>
              <IonLabel>お名前</IonLabel>
              <IonLabel>{profile.name}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>年齢</IonLabel>
              <IonLabel>
                {convertDate(new Date(profile.date_of_birth))}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>性別</IonLabel>
              <IonLabel>{genderDict[profile.gender]}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>属性</IonLabel>
              <IonLabel>
                {profile.down_syndrome ? "ダウン症候群" : "なし"}
              </IonLabel>
            </IonItem>

            <IonCardHeader>
              <IonCardTitle className="ion-text-center" color="secondary">
                ご家族・代諾者
              </IonCardTitle>
            </IonCardHeader>
            <IonItem>
              <IonLabel>お名前</IonLabel>
              <IonLabel>{profile.name_of_family}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>携帯電話</IonLabel>
              <IonLabel>{profile.tel_of_family}</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>メールアドレス</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>{profile.email_of_family}</IonLabel>
            </IonItem>
          </IonCard>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px",
            }}
          >
            <IonButton
              onClick={() => {
                setEdit(true);
              }}
              color="primary"
              style={{
                width: "240px",
              }}
            >
              編集する
            </IonButton>
          </div>
          <IonModal isOpen={edit} onWillDismiss={() => setEdit(false)}>
            <ProfileRegistration
              setHasUserInfo={setEdit}
              userInfo={profile}
              setUserInfo={setProfile}
            />
          </IonModal>
        </div>
      )}
    </div>
  );
}

export default function Profile() {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="Header">
          <IonTitle>プローフィール</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/`} text="ホーム" />
          </IonButtons>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Content />
      </IonContent>
    </IonPage>
  );
}
