import "./Home.css";
import paImage from "../images/panda_1.png";
import taImage from "../images/drum.png";
import kaImage from "../images/castanets.png";
import raImage from "../images/trumpet.png";
import folders from "../assets/task.json";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLoading,
  useIonViewWillEnter,
  IonModal,
} from "@ionic/react";
import Calendar from "../components/Calendar";
import { useEffect, useState } from "react";
import {
  getCalender,
  getCalenderStamp,
  getUserInfo,
  getRunningDays,
} from "../services/api";
import { useAuth0 } from "@auth0/auth0-react";
import ProfileRegistration from "../components/ProfileRegistration";
import { RUNNING_DAYS_MESSAGE } from "../assets/Common";

const images = {
  1: paImage,
  2: taImage,
  3: kaImage,
  4: raImage,
};

function Content() {
  const { getAccessTokenSilently } = useAuth0();
  const [calendarData, setCalendarData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [runningDays, setRunningDays] = useState(0);

  function updateLoding(calendar) {
    if (calendar != null) {
      setIsLoading(false);
    }
  }

  const date = new Date();
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth() + 1);

  useEffect(() => {
    const getCalendar = async () => {
      const calendarRecord = await getCalender(
        year,
        month,
        getAccessTokenSilently
      );
      const stampRecord = await getCalenderStamp(
        year,
        month,
        getAccessTokenSilently
      );
      const data = {
        calendar: calendarRecord.calendar_data,
        firstIdx: calendarRecord.firstIdx,
        stampRecord: stampRecord,
      };
      setCalendarData(data);
      updateLoding(calendarRecord.calendar_data);
    };
    getCalendar();
  }, [month, year, getAccessTokenSilently]);

  useIonViewWillEnter(() => {
    const getCalendar = async () => {
      const calendarRecord = await getCalender(
        year,
        month,
        getAccessTokenSilently
      );
      const stampRecord = await getCalenderStamp(
        year,
        month,
        getAccessTokenSilently
      );
      const data = {
        calendar: calendarRecord.calendar_data,
        firstIdx: calendarRecord.firstIdx,
        stampRecord: stampRecord,
      };
      setCalendarData(data);
      updateLoding(calendarRecord.calendar_data);
    };
    const getRunning = async () => {
      const _runningDays = await getRunningDays(getAccessTokenSilently);
      setRunningDays(_runningDays);
    };
    getRunning();
    getCalendar();
  }, [month, year, getAccessTokenSilently]);

  function updateNextMonth() {
    if (month + 1 <= 12) {
      setMonth(month + 1);
    } else {
      setMonth(1);
      setYear(year + 1);
    }
  }
  function updateLastMonth() {
    if (month - 1 > 0) {
      setMonth(month - 1);
    } else {
      setMonth(12);
      setYear(year - 1);
    }
  }

  function getRunningMessage(days) {
    if (days <= 7) {
      return RUNNING_DAYS_MESSAGE[7];
    } else if (days <= 14) {
      return RUNNING_DAYS_MESSAGE[14];
    } else {
      return "素晴らしい！！";
    }
  }

  return (
    <>
      {isLoading ? (
        <IonLoading
          cssClass="my-custom-class"
          isOpen={isLoading}
          message={"読み込み中"}
        />
      ) : (
        <div>
          {runningDays > 1 && (
            <div>
              <IonItem lines="none" className="praise">
                {runningDays}日続いたね！{getRunningMessage(runningDays)}
              </IonItem>
            </div>
          )}
          <Calendar
            calendarData={calendarData}
            updateNextMonth={updateNextMonth}
            updateLastMonth={updateLastMonth}
            month={month}
            year={year}
            runningDays={runningDays}
          />
          <IonList style={{ marginTop: "20px" }}>
            {folders.map((folder) => {
              return (
                <IonItem
                  color="secondary"
                  lines="none"
                  routerLink={`/task/${folder.id}`}
                  key={folder.id}
                  className="List"
                >
                  <IonLabel>「{folder.name}」の練習</IonLabel>
                  <IonImg slot="start" src={images[folder.id]} />
                </IonItem>
              );
            })}
          </IonList>
        </div>
      )}
    </>
  );
}

export default function Home() {
  const { getAccessTokenSilently } = useAuth0();
  const [hasUserInfo, setHasUserInfo] = useState(true);

  useIonViewWillEnter(() => {
    //XXX: 毎回リクエスト飛ばすのはどうなの
    const getHasUserInfo = async () => {
      const _hasUserInfo = await getUserInfo(getAccessTokenSilently);
      if (_hasUserInfo === null) {
        setHasUserInfo(false);
      } else {
        setHasUserInfo(true);
      }
    };
    getHasUserInfo();
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="Header">
          <IonTitle>パタカラプラス</IonTitle>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Content />
      </IonContent>
      <IonModal isOpen={!hasUserInfo} backdropDismiss={false}>
        <ProfileRegistration
          setHasUserInfo={setHasUserInfo}
          userInfo={null}
          setUserInfo={null}
        />
      </IonModal>
    </IonPage>
  );
}
