import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonMenuButton,
  IonContent,
  IonItem,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonRadio,
  IonRadioGroup,
  IonButton,
  IonAlert,
} from "@ionic/react";
import { useState } from "react";
import "./Questionnaires.css";
import { useAuth0 } from "@auth0/auth0-react";
import { postAnswer } from "../../services/api";
import { useHistory } from "react-router";
import { QuestionnairesKey } from "../../assets/Common";
import { convertDate } from "../../assets/Common";

const rankDict = {
  1: "良くわかる",
  2: "時々わからない語がある程度",
  3: "聞き手が話題を知っているとどうやらわかる程度",
  4: "時々わかる語があるという程度",
  5: "全く理解不能",
};

function Content() {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const [evaluation, setEvaluation] = useState(0);
  const [isFamily, setIsFamily] = useState(null);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showSuccesDialog, setShowSuccesDialog] = useState(false);
  const date = new Date();

  async function register() {
    const answerInfo = {
      ques1: evaluation,
      isFamily,
      date,
    };
    try {
      await postAnswer(
        QuestionnairesKey.SPEECH_INTELLIGIBILITY,
        JSON.stringify(answerInfo),
        getAccessTokenSilently
      );
    } catch (e) {
      setShowErrorDialog(true);
      return;
    }
    setShowSuccesDialog(true);
    return;
  }

  function clearForm() {
    setIsFamily(null);
    setEvaluation(0);
  }

  function filledOut() {
    if (isFamily === null) {
      return false;
    }
    if (evaluation === 0) {
      return false;
    }
    return true;
  }

  return (
    <div>
      {/* TODO:通知的なアナウンス */}
      {/* <IonItem>
        <IonLabel>n月までにあとn人に出してもらいましょう</IonLabel>
      </IonItem> */}
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className="ion-text-center" color="secondary">
            会話明瞭度のアンケート
          </IonCardTitle>
        </IonCardHeader>
        <IonItem>
          <IonLabel>判定日</IonLabel>
          {convertDate(date)}
        </IonItem>
        <IonItem>
          <IonLabel>判定者</IonLabel>
          <IonRadioGroup className="gender" value={isFamily}>
            <IonItem lines="none">
              <IonLabel className="radio_label">家族</IonLabel>
              <IonRadio
                slot="start"
                value={true}
                onClick={(e) => {
                  setIsFamily(e.target.value);
                }}
              ></IonRadio>
            </IonItem>
            <IonItem lines="none">
              <IonLabel className="radio_label">家族以外</IonLabel>
              <IonRadio
                slot="start"
                value={false}
                onClick={(e) => {
                  setIsFamily(e.target.value);
                }}
              ></IonRadio>
            </IonItem>
          </IonRadioGroup>
        </IonItem>
        <IonItem lines="none">
          <IonLabel>会話の明瞭さ</IonLabel>
        </IonItem>
        <IonItem>
          <div>
            <IonRadioGroup value={evaluation}>
              {Object.entries(rankDict).map(([rank, txt]) => {
                return (
                  <IonItem lines="none" key={rank}>
                    <IonLabel className="ion-text-wrap questionnaires_label">
                      {txt}
                    </IonLabel>
                    <IonRadio
                      style={{ marginRight: "8px" }}
                      slot="start"
                      value={rank}
                      onClick={(e) => {
                        setEvaluation(e.target.value);
                      }}
                    ></IonRadio>
                  </IonItem>
                );
              })}
            </IonRadioGroup>
          </div>
        </IonItem>
      </IonCard>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "10px" }}
      >
        {/* TODO:本当はバックボタンで戻れるようにしたい */}
        <IonButton
          onClick={register}
          disabled={!filledOut()}
          style={{
            width: "240px",
            color: "white",
          }}
        >
          送信
        </IonButton>
      </div>
      <IonAlert
        isOpen={showErrorDialog}
        onDidDismiss={() => setShowErrorDialog(false)}
        header="エラー"
        message={"エラーが発生しました。もう一度やり直してください。"}
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={showSuccesDialog}
        on
        onDidDismiss={() => {
          setShowSuccesDialog(false);
          clearForm();
          history.go(-1);
        }}
        header="送信完了"
        message={"アンケートの回答を受け付けました。"}
        buttons={["OK"]}
      />
    </div>
  );
}

export default function SpeechIntelligibility() {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="Header">
          <IonTitle>会話明瞭度に関するアンケート</IonTitle>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/questionnaires/status/${QuestionnairesKey.SPEECH_INTELLIGIBILITY}`}
              text="状態"
            />
          </IonButtons>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Content />
      </IonContent>
    </IonPage>
  );
}
