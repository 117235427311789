import { IonCard, IonSlide, IonCardHeader, IonCardTitle } from "@ionic/react";

function HelloPatakara() {
  return (
    <IonSlide>
      <IonCard className="card_height">
        <IonCardHeader>
          <IonCardTitle className="ion-text-center">はじめに</IonCardTitle>
        </IonCardHeader>
        <div>
          <div className="text_left text_wrapper">
            コロナウイルス感染症の影響、発達障群などのリハビリニーズの高まり、各家族のライフスタイルの変化などで、リハビリセンターなどでニーズに即したリハビリを行うことに支障が出てきていることが危惧されます。
            <br />
            <span className="emphasis_text">
              そのため、自宅でライフスタイルに合わせて、歌って踊ることでリハビリに準じるものができればとの目的で、リハビリテーション理学療法士、作業療法士、言語聴覚士の意見を参照して、多くの音楽関係者が尽力して、「パタカラプラス」のコンテンツが作られています。
            </span>
            <br />
            併せて、実際にその効果をみるにあたり、上記の専門機関での評価は難しいことが想定されるため、
            <span className="emphasis_text">
              各個人のスマートフォンなどで人工知能（AI）を活用することで、自宅などで手軽に評価ができるものの開発を検討しています。
            </span>
          </div>
        </div>
      </IonCard>
    </IonSlide>
  );
}

export default HelloPatakara;
