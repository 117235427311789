import { IonCard, IonCardTitle, IonCardHeader, IonImg } from "@ionic/react";
import { useMemo } from "react";
import CrispnessChart from "./chart/CrispnessChart";
import RecognitionChart from "./chart/RecognitionChart";
import greatStamp from "../images/great.png";
import goodStamp from "../images/good.png";
import fightStamp from "../images/fight.png";
import { DEFAULT_REMINING_TIME } from "../assets/Common";

function ScoreStamp({ score }) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          position: "relative",
          width: "120px",
          height: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            fontSize: "1.15rem",
            fontWeight: "500",
            top: "60px",
            left: "21px",
          }}
        >
          <span
            style={{
              fontSize: "1.5rem",
              fontWeight: "500",
            }}
          >
            {Math.round(score)}
          </span>
          点
        </div>
        <img
          style={{
            height: "85px",
            width: "85px",
            position: "absolute",
            top: "8px",
            left: "45px",
          }}
          alt="結果"
          src={getScoreStamp(score)}
        />
      </div>
    </div>
  );
}

function ScoreCard({ score, pronunciation }) {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ width: "120px", height: "100%" }}>
        <ScoreStamp score={score} />
      </div>

      <div style={{ padding: "0 0.5rem", width: "calc(100% - 120px)" }}>
        <div>「{pronunciation} 」</div>
        <div style={{ textAlign: "right" }}>って聞こえたよ</div>
      </div>
    </div>
  );
}

function SleepSystem() {
  return (
    <div
      style={{
        width: "100%",
        height: "150px",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>お休み中です</div>
    </div>
  );
}

function getScoreStamp(score) {
  if (score >= 85) {
    return greatStamp;
  } else if (score >= 60) {
    return goodStamp;
  }
  return fightStamp;
}

function correctPronunciationScore(pronunciation, model1, model2) {
  let sum = 0;
  let emptyCharCount = 0;
  for (const p of pronunciation) {
    if (p === model1 || p === model2) {
      sum += 1;
    }
    if (p === " ") {
      emptyCharCount += 1;
    }
  }
  return sum / (pronunciation.length - emptyCharCount);
}

export default function ResultDisplay({
  sentenceData,
  crispness,
  watsonData,
  amiData,
  gouData,
  folder,
}) {
  const pronunciationCnt = useMemo(() => {
    /* MEMO: とりあえずはクライアント側でもつ */
    const maxValue = crispness.amplitude_data.reduce(function (a, b) {
      return Math.max(a, b);
    }, -Infinity);
    const baseValue = maxValue * 0.75;

    function compare(data) {
      let c = 0;
      for (let i = 1; i < data.length - 2; i++) {
        //TODO:i=0, i=data.lengthが極大の時が取れていないので直す
        const upperBaseValue = data[i] >= baseValue ? true : false;
        const isMaximum =
          data[i - 1] < data[i] && data[i] > data[i + 1] ? true : false;
        if (upperBaseValue && isMaximum) {
          c += 1;
        }
      }
      return c;
    }
    const count = compare(crispness.amplitude_data);
    return count;
  }, [crispness.amplitude_data]);

  return (
    <IonCard>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px",
        }}
      >
        {[85, 60, 0].map((score) => (
          <div style={{ display: "flex", alignItems: "center" }} key={score}>
            <IonImg
              slot="start"
              src={getScoreStamp(score)}
              style={{ height: "40px" }}
            />
            <div>{score} 点以上</div>
          </div>
        ))}
      </div>

      <IonCardHeader>
        <IonCardTitle className="ion-text-center" color="secondary">
          じゅりちゃん
        </IonCardTitle>
      </IonCardHeader>
      {sentenceData ? (
        <div
          style={{
            width: "100%",
            height: "200px",
            padding: "10px",
          }}
        >
          {sentenceData.length === 0 ? (
            <div className="center-text">
              聞き取りできませんでした。
              <br />
              もう一度試してみてください。
            </div>
          ) : (
            <RecognitionChart data={sentenceData} />
          )}
        </div>
      ) : (
        <SleepSystem />
      )}
      <IonCardHeader>
        <IonCardTitle className="ion-text-center" color="secondary">
          わたるくん
        </IonCardTitle>
      </IonCardHeader>
      <div
        style={{
          width: "100%",
          height: "150px",
          padding: "10px",
        }}
      >
        {watsonData.length === 0 ? (
          <div className="center-text">
            聞き取りできませんでした。
            <br />
            もう一度試してみてください。
          </div>
        ) : (
          <div style={{ height: "100%" }}>
            <ScoreCard
              score={
                correctPronunciationScore(
                  watsonData[0].pronunciation,
                  folder.hiraganaPronunciation,
                  folder.katakanaPronunciation
                ) * 100
              }
              pronunciation={watsonData[0].pronunciation}
            />
          </div>
        )}
      </div>
      <IonCardHeader>
        <IonCardTitle className="ion-text-center" color="secondary">
          あみちゃん
        </IonCardTitle>
      </IonCardHeader>
      {amiData ? (
        <div
          style={{
            width: "100%",
            height: "150px",
            padding: "10px",
          }}
        >
          {Object.keys(amiData).length === 0 ? (
            <div className="center-text">
              聞き取りできませんでした。
              <br />
              もう一度試してみてください。
            </div>
          ) : (
            <ScoreCard
              score={amiData.accuracy * 100}
              pronunciation={amiData.pronunciation}
            />
          )}
        </div>
      ) : (
        <SleepSystem />
      )}

      <IonCardHeader>
        <IonCardTitle className="ion-text-center" color="secondary">
          ごうくん
        </IonCardTitle>
      </IonCardHeader>
      {gouData ? (
        <div
          style={{
            width: "100%",
            height: "150px",
            padding: "10px",
          }}
        >
          {Object.keys(gouData).length === 0 ? (
            <div className="center-text">
              聞き取りできませんでした。
              <br />
              もう一度試してみてください。
            </div>
          ) : (
            <ScoreCard
              score={gouData.accuracy * 100}
              pronunciation={gouData.pronunciation}
            />
          )}
        </div>
      ) : (
        <SleepSystem />
      )}
      <IonCardHeader>
        <IonCardTitle className="ion-text-center" color="secondary">
          発音回数
        </IonCardTitle>
      </IonCardHeader>
      <div
        style={{
          width: "100%",
          padding: "10px",
        }}
      >
        {crispness === -1 || crispness.amplitude_data.length === 0 ? (
          <div
            className="center-text"
            style={{
              height: "65px",
            }}
          >
            聞き取りできませんでした。
            <br />
            もう一度試してみてください。
          </div>
        ) : (
          <div
            className="center-text"
            style={{
              fontSize: "1.5rem",
              fontWeight: "500",
              padding: "12px 0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              1秒あたり&ensp;
              <span>{pronunciationCnt / DEFAULT_REMINING_TIME}回</span>
            </div>
            <div style={{ fontSize: "1rem", padding: "8px" }}>
              ※1秒あたり6回が目安となっています
            </div>
          </div>
        )}
      </div>
      <IonCardHeader>
        <IonCardTitle className="ion-text-center" color="secondary">
          ハキハキ度
        </IonCardTitle>
      </IonCardHeader>
      <div
        style={{
          width: "100%",
          height: "150px",
          padding: "10px",
        }}
      >
        {crispness === -1 || crispness.score_chart_data.length === 0 ? (
          <div className="center-text">
            聞き取りできませんでした。
            <br />
            もう一度試してみてください。
          </div>
        ) : (
          <div style={{ height: "100%" }}>
            <ScoreStamp score={crispness.score} />
          </div>
        )}
      </div>
      <IonCardHeader>
        <IonCardTitle className="ion-text-center" color="secondary">
          はっきりグラフ
        </IonCardTitle>
      </IonCardHeader>
      {crispness === -1 || crispness.amplitude_data.length === 0 ? (
        <div
          className="center-text"
          style={{
            width: "100%",
            height: "200px",
            padding: "10px",
          }}
        >
          聞き取りできませんでした。
          <br />
          もう一度試してみてください。
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "300px",
            padding: "10px",
          }}
        >
          <CrispnessChart data={crispness.amplitude_data} />
        </div>
      )}
    </IonCard>
  );
}
