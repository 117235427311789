import { useMemo, useRef } from "react";
import * as d3 from "d3";
import { useResize } from "./useResize";
import ScrollLineChart from "./ScrollLineChart";
import { DISPLAY_COUNT } from "../../assets/Common";
import { SERIES, SERIES_COLORS } from "../../assets/Common";

export default function ProgressChart({ data }) {
  const wrapperRef = useRef();
  const size = useResize(wrapperRef);

  const chart = useMemo(() => {
    const { width: displayWidth, height: displayHeight } = size;
    const margin = {
      top: 30,
      right: 30,
      bottom: 40,
      left: 15,
    };
    const colorScale = d3.scaleOrdinal().domain(SERIES).range(SERIES_COLORS);
    const contentWidth = Math.max(0, displayWidth - margin.left - margin.right);
    const contentHeight = Math.max(
      0,
      displayHeight - margin.top - margin.bottom
    );

    const dx = contentWidth / DISPLAY_COUNT;
    const xScale = d3
      .scaleLinear()
      .domain([0, DISPLAY_COUNT])
      .range([dx / 2, contentWidth + dx / 2])
      .nice();
    const yScale = d3
      .scaleLinear()
      .domain([0, 100])
      .range([contentHeight, 0])
      .nice();

    return {
      width: displayWidth,
      height: displayHeight,
      contentWidth,
      contentHeight,
      margin,
      dx: dx,
      lines: data.series.map(({ label, values }) => {
        return {
          label: label,
          color: colorScale(label),
          points: values.map((d, i) => {
            return { x: xScale(i), y: yScale(d) };
          }),
        };
      }),
      xTicks: data.labels.map((label, i) => ({
        offset: 8,
        fontSize: 7,
        label,
        x: xScale(i),
      })),
      yTicks: yScale.ticks(10).map((d) => ({ label: `${d}`, y: yScale(d) })),
      pointR: 4,
    };
  }, [data, size]);

  return (
    <div ref={wrapperRef} className="chart">
      <ScrollLineChart chart={chart} />
    </div>
  );
}
