import { useMemo, useRef } from "react";
import * as d3 from "d3";
import { useResize } from "./useResize";

export default function RecognitionChart({ data }) {
  const wrapperRef = useRef();
  const size = useResize(wrapperRef);

  const chart = useMemo(() => {
    const { width: displayWidth, height: displayHeight } = size;
    const margin = {
      top: 10,
      right: 10,
      bottom: 30,
      left: 10,
    };
    const contentWidth = Math.max(0, displayWidth - margin.left - margin.right);
    const contentHeight = Math.max(
      0,
      displayHeight - margin.top - margin.bottom
    );

    const dy = contentHeight;
    const xScale = d3.scaleLinear().domain([0, 100]).range([0, contentWidth]);
    const yScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([dy / 2, contentHeight + dy / 2]);
    const barHeight = 40;

    const sortedData = data.map(({ pronunciation, score, correct }) => {
      return {
        label: pronunciation,
        value: score,
        color: correct ? "#ff9292" : "#fffcd8",
      };
    });

    let xOffset = 0;
    const stack = sortedData.map((item) => {
      const x0 = xOffset;
      const x1 = xOffset + item.value;
      xOffset += item.value;
      return {
        label: item.label,
        value: item.value,
        color: item.color,
        x: xScale(x0),
        y: yScale(0) - barHeight / 2,
        width: xScale(x1 - x0),
        height: barHeight,
      };
    });

    return {
      width: displayWidth,
      height: displayHeight,
      contentWidth,
      contentHeight,
      margin,
      stack,
      xTicks: xScale.ticks(11).map((x) => ({ label: `${x}`, x: xScale(x) })),
    };
  }, [data, size]);

  const percentageFormat = d3.format(".1f");

  return (
    <div ref={wrapperRef} className="chart">
      <svg viewBox={`0 0 ${chart.width} ${chart.height}`}>
        <g transform={`translate(${chart.margin.left},${chart.margin.top})`}>
          <g transform={`translate(0,${chart.contentHeight})`}>
            {chart.xTicks.map((tick, i) => {
              return (
                <g key={i} transform={`translate(${tick.x},0)`}>
                  <line
                    x1="0"
                    y1="0"
                    x2="0"
                    y2={-chart.contentHeight}
                    fill="none"
                    stroke="#ccc"
                  />
                  <text
                    x="0"
                    y="2"
                    textAnchor="middle"
                    dominantBaseline="text-before-edge"
                    fill="#888"
                    fontFamily="sans-serif"
                    fontSize="10"
                    fontWeight="bold"
                  >
                    {tick.label}
                  </text>
                </g>
              );
            })}
          </g>
          <g>
            {chart.stack.map((item, i) => {
              return (
                <g
                  key={i}
                  transform={`translate(${item.x + item.width / 2},${
                    item.y + item.height / 2
                  })`}
                >
                  <rect
                    x={-item.width / 2}
                    y={-item.height / 2}
                    width={item.width}
                    height={item.height}
                    fill={item.color}
                    stroke="#ccc"
                  />
                  {item.width > 40 && (
                    <>
                      <text
                        textAnchor="middle"
                        dominantBaseline="text-after-edge"
                        fill="#444"
                        fontFamily="sans-serif"
                        fontSize="10"
                        fontWeight="bold"
                      >
                        {item.label}
                      </text>
                      <text
                        textAnchor="middle"
                        dominantBaseline="text-before-edge"
                        fill="#444"
                        fontFamily="sans-serif"
                        fontSize="10"
                        fontWeight="bold"
                      >
                        {percentageFormat(item.value)}%
                      </text>
                    </>
                  )}
                </g>
              );
            })}
          </g>
        </g>
      </svg>
    </div>
  );
}
