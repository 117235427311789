export const DISPLAY_COUNT = 5;
export const SERIES = [
  "じゅりちゃん",
  "わたるくん",
  "ハキハキ度",
  "あみちゃん",
  "ごうくん",
];
export const SERIES_COLORS = [
  "#ff9292",
  "#91C8FF",
  "#FFB800",
  "#84A98C",
  "#0B4F6C",
];

export const RUNNING_DAYS_MESSAGE = {
  7: "この調子で続けよう！",
  14: "すごい！",
};

export const QuestionnairesKey = {
  SPEECH_INTELLIGIBILITY: "SPEECH_INTELLIGIBILITY",
  EATING_AND_SWALLOWING: "EATING_AND_SWALLOWING",
};

export function convertDate(date) {
  return date.toLocaleDateString();
}

export const DEFAULT_REMINING_TIME = 5;
export const DEFAULT_EXTRA_TIME = 2;
