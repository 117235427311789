/** questionnaries, answers関係 */
import { requestPost, requestGet } from "./request.js";

export async function postAnswer(quesKey, body, getAccessTokenSilently) {
  return requestPost(`/api/answer/${quesKey}`, body, getAccessTokenSilently)
    .then((response) => {
      return response.json();
    })
    .catch((e) => {
      throw e;
    });
}

export async function getQuestionnaires(
  questionnairesKey,
  getAccessTokenSilently
) {
  const response = await requestGet(
    `/api/questionnaires/${questionnairesKey}`,
    getAccessTokenSilently
  );
  return response.json();
}

export async function getAnswers(questionnairesKey, getAccessTokenSilently) {
  const response = await requestGet(
    `/api/answers/${questionnairesKey}`,
    getAccessTokenSilently
  );
  return response.json();
}

export async function getAnswer(id, getAccessTokenSilently) {
  const response = await requestGet(
    `/api/answer/${id}`,
    getAccessTokenSilently
  );
  return response.json();
}
