import { IonCard } from "@ionic/react";
import { useMemo, useRef } from "react";

import { useResize } from "./chart/useResize";
import paImage from "../images/panda_1.png";
import taImage from "../images/drum.png";
import kaImage from "../images/castanets.png";
import raImage from "../images/trumpet.png";

export default function Calendar({
  calendarData,
  updateLastMonth,
  updateNextMonth,
  year,
  month,
}) {
  const calendar = calendarData.calendar;
  const stamp = calendarData.stampRecord;
  const firstIdx = calendarData.firstIdx;
  const date = new Date();
  const thisMonth = date.getMonth() + 1;
  const thisYear = date.getFullYear();

  const wrapperRef = useRef();
  const size = useResize(wrapperRef);

  const chart = useMemo(() => {
    const displayWidth = size.width;
    const margin = {
      top: 10,
      right: 15,
      bottom: 10,
      left: 15,
    };
    const contentWidth = Math.max(0, displayWidth - margin.left - margin.right);

    const dw = Math.min(50, contentWidth / 7);
    const dh = dw + 10;
    const displayHeight = dh * (calendar.length + 1.5);

    const contentHeight = Math.max(
      0,
      displayHeight - margin.top - margin.bottom
    );

    return {
      width: displayWidth,
      height: displayHeight,
      contentWidth,
      contentHeight,
      margin,
      dw,
      dh,
    };
  }, [size, calendar]);

  return (
    <IonCard ref={wrapperRef} style={{ paddingTop: "12px", marginTop: "12px" }}>
      <div className="chart">
        <svg viewBox={`0 0 ${chart.width} ${chart.height}`}>
          <g
            transform={`translate(${
              chart.margin.left + chart.contentWidth / 2 - chart.dw * 3.5
            },${chart.margin.top})`}
          >
            <text
              x={chart.dw * 3.5}
              y="25"
              fontSize="25"
              textAnchor="middle"
              dominantBaseline="central"
            >
              {year}年 {month}月
            </text>
            {!(thisYear === year && thisMonth === month) && (
              <g
                transform={`translate(${chart.dw * 3.5 + 80},${
                  chart.margin.top + 7.5
                })
            scale(0.3 0.3)`}
                onClick={updateNextMonth}
              >
                <polygon
                  points="0, 0, 0, 60, 50, 30"
                  className="triangle"
                  fill="#888888"
                />
                <text
                  x={chart.dw * 3.5}
                  y="30"
                  fontSize="40"
                  textAnchor="middle"
                  dominantBaseline="central"
                >
                  {month === 12 ? 1 : month + 1}月 に進む
                </text>
              </g>
            )}
            <g
              transform={`translate(${chart.dw * 3.5 - 80},${
                chart.margin.top + 7.5
              })
            scale(0.3 0.3)`}
              onClick={updateLastMonth}
            >
              <polygon
                points="0, 0, 0, 60, -50, 30"
                className="triangle"
                fill="#888888"
              />
              <text
                x={-chart.dw * 3.5}
                y="30"
                fontSize="40"
                textAnchor="middle"
                dominantBaseline="central"
              >
                {month === 1 ? 12 : month - 1}月 に戻る
              </text>
            </g>

            {/*曜日*/}
            <g>
              {["月", "火", "水", "木", "金", "土", "日"].map((day, idx) => {
                return (
                  <g key={idx}>
                    <rect
                      x={0 + idx * chart.dw}
                      y="50"
                      width={chart.dw}
                      height="22.5"
                      fill="#ffbfbf"
                      stroke="black"
                      strokeWidth="1"
                    />
                    <text
                      x={0 + idx * chart.dw + chart.dw / 2}
                      y="60"
                      fontSize="15"
                      textAnchor="middle"
                      dominantBaseline="central"
                    >
                      {day}
                    </text>
                  </g>
                );
              })}
            </g>

            {calendar.length > 0 &&
              calendar?.map((week, dy) => {
                return week?.map((d, dx) => {
                  return (
                    <g key={dx}>
                      <rect
                        x={0 + dx * chart.dw}
                        y={72.5 + dy * chart.dh}
                        width={chart.dw}
                        height={chart.dh}
                        fill="white"
                        stroke="black"
                        strokeWidth="1"
                      />
                      <text
                        x={0 + dx * chart.dw + chart.dw / 2}
                        y={85 + dy * chart.dh}
                        fontSize="15"
                        textAnchor="middle"
                        dominantBaseline="central"
                      >
                        {d[0] !== 0 && d[0]}
                      </text>
                    </g>
                  );
                });
              })}

            {stamp.length > 0 &&
              stamp.map((s) => {
                const date = new Date(s.created_date);
                const idx = date.getDate() + firstIdx - 1;
                const dx = idx % 7;
                const dy = parseInt(idx / 7);
                return (
                  <g key={date}>
                    {s.pa_practice && (
                      <image
                        x={0 + dx * chart.dw + 5}
                        y={90 + dy * chart.dh}
                        href={paImage}
                        height="20"
                        width="20"
                      />
                    )}
                    {s.ta_practice && (
                      <image
                        x={0 + dx * chart.dw + chart.dw / 2}
                        y={90 + dy * chart.dh}
                        href={taImage}
                        height="20"
                        width="20"
                      />
                    )}
                    {s.ka_practice && (
                      <image
                        x={0 + dx * chart.dw + 5}
                        y={110 + dy * chart.dh}
                        href={kaImage}
                        height="20"
                        width="20"
                      />
                    )}
                    {s.ra_practice && (
                      <image
                        x={0 + dx * chart.dw + chart.dw / 2}
                        y={110 + dy * chart.dh}
                        href={raImage}
                        height="20"
                        width="20"
                      />
                    )}
                  </g>
                );
              })}
          </g>
        </svg>
      </div>
    </IonCard>
  );
}
