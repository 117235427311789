import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonMenuButton,
  IonTitle,
  IonModal,
  IonProgressBar,
  IonImg,
  IonIcon,
} from "@ionic/react";
import { useState, useMemo, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { startRecording } from "../services/recording";
import { postRecord } from "../services/api";
import { useAuth0 } from "@auth0/auth0-react";
import "./Recording.css";
import folders from "../assets/task.json";
import hourglass from "../images/hourglass.gif";
import paImage from "../images/panda.png";
import paImageLeft from "../images/panda_1.png";
import { closeOutline } from "ionicons/icons";
import ErrorPopup from "../components/ErrorPopup";
import { DEFAULT_REMINING_TIME } from "../assets/Common";

export default function Record() {
  // HACK:変数もう少し少なくできそう？
  const history = useHistory();
  const { folderId } = useParams();
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const [resultId, setResultId] = useState(null);
  const [remainingTime, setRemainingTime] = useState(DEFAULT_REMINING_TIME);
  const [isStartCount, setIsStartCount] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const time = useRef();
  const reference = useRef(DEFAULT_REMINING_TIME);
  const recorderRef = useRef(null);
  const [showAlert, setShowAlert] = useState(false);

  const folder = useMemo(() => {
    for (const d of folders) {
      if (d.id === Number(folderId)) {
        return d;
      }
    }
    return null;
  }, [folderId]);

  //HACK: 現在時刻からの差をとった方が正しく測れるようになる
  function startTime() {
    time.current = setInterval(() => {
      reference.current--;
      setRemainingTime((t) => t - 1);
      if (reference.current <= 0) {
        confirmRecording();
      }
    }, 1000);
  }

  function stopTime() {
    clearTimeout(time.current);
    reference.current = DEFAULT_REMINING_TIME;
    setRemainingTime(DEFAULT_REMINING_TIME);
  }

  async function cancelRecording() {
    await recorderRef.current.cancel();
    recorderRef.current = null;
    stopTime();
    setIsStartCount(false);
  }

  async function confirmRecording() {
    stopTime();
    setIsWaiting(true);
    const wav = await recorderRef.current.stop();
    setTimeout(async () => {
      setLoading(true);
      setIsOpen(true);

      try {
        const result = await postRecord(folderId, wav, getAccessTokenSilently);
        recorderRef.current = null;
        setResultId(result.id);
        setLoading(false);
        setIsStartCount(false);
        setIsWaiting(false);
      } catch (e) {
        setShowAlert(true);
        recorderRef.current = null;
        setLoading(false);
        setIsStartCount(false);
      }
    }, 2000);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="Header">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" text="一覧" />
          </IonButtons>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="recordingButton">
          <div className="titleText">
            <span className="word">{folder.name}</span> を
            <br />
            録音します
          </div>
          <div className="instructionsText">
            スタートボタンを押したら
            <br />
            3秒間
            <br />「{folder.name}」と言いましょう
          </div>
          <IonButton
            expand="block"
            shape="round"
            size="large"
            onClick={async () => {
              if (recorderRef.current != null) {
                return;
              }
              recorderRef.current = await startRecording();
              setIsStartCount(true);
              setIsWaiting(false);
              startTime();
            }}
          >
            録音スタート
          </IonButton>
        </div>
      </IonContent>
      {/* 録音中のモーダル */}
      <IonModal
        className="recordingModal"
        isOpen={isStartCount}
        onDidDismiss={async (event) => {
          if (event.detail.role === "backdrop") {
            cancelRecording();
          }
        }}
      >
        <div className="wrapper">
          {isWaiting ? (
            <div className="recordingModal">
              <div className="recordingModalText">
                <div className="recordingHeader">録音終了！</div>
                <div>採点を始めます</div>
              </div>
              <IonProgressBar value={1}></IonProgressBar>
            </div>
          ) : (
            <div className="recordingModal">
              <div className="recordingModalText">
                <div className="recordingHeader">録音中</div>
              </div>
              <IonProgressBar
                value={
                  (DEFAULT_REMINING_TIME - remainingTime) /
                  DEFAULT_REMINING_TIME
                }
              ></IonProgressBar>
            </div>
          )}

          <IonButtons>
            <div className="buttonBox">
              <IonButton
                className="unShadowButton"
                id="modalButton"
                disabled={isWaiting}
                onClick={async () => {
                  cancelRecording();
                }}
              >
                キャンセル
              </IonButton>
            </div>
          </IonButtons>
        </div>
      </IonModal>
      {/* 採点中のモーダル */}
      <IonModal
        isOpen={isOpen}
        backdropDismiss={!loading}
        onDidDismiss={async (event) => {
          if (event.detail.role === "backdrop") {
            setIsOpen(false);
            setResultId(null);
          }
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle> </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="unShadowButton"
                onClick={() => {
                  setIsOpen(false);
                  setResultId(null);
                  setShowAlert(false);
                }}
                disabled={loading}
              >
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div className="waitingModal">
            {loading ? (
              <div className="waitingModalContainer">
                <div className="waitingTextTitle">ただいま採点中...</div>
                <div className="waitingText">15秒ほどお待ちください</div>
                <div className="waitingImageContainer">
                  <div className="waitingImage">
                    <IonImg slot="start" src={hourglass} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="waitingModalContainer">
                <div className="nextTextTitle">採点終了</div>
                <div className="showResultButton">
                  <IonButton
                    shape="round"
                    size="large"
                    onClick={() => {
                      setIsOpen(false);
                      history.replace(`/task/${folderId}/record/${resultId}`);
                      setResultId(null);
                    }}
                    disabled={loading || showAlert}
                  >
                    結果を見る
                  </IonButton>
                </div>
                <div className="imageContainer">
                  <div className="pandaImage">
                    <IonImg slot="start" src={paImageLeft} />
                  </div>
                  <div className="pandaImage">
                    <IonImg slot="start" src={paImage} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </IonContent>
      </IonModal>
      {showAlert && (
        <ErrorPopup
          headerText={"エラー"}
          subHeader={"採点に失敗しました。"}
          msg={"上のばつ印からダイアログを閉じてやり直してください。"}
        />
      )}
    </IonPage>
  );
}
