import * as d3 from "d3";

export default function LineChart({ chart }) {
  const line = d3
    .line()
    .x(({ x }) => x)
    .y(({ y }) => y);
  return (
    <svg viewBox={`0 0 ${chart.width} ${chart.height}`}>
      <g transform={`translate(${chart.margin.left},${chart.margin.top})`}>
        <g transform={`translate(0,${chart.contentHeight})`}>
          {chart.xTicks.map((tick, i) => {
            return (
              <g key={i} transform={`translate(${tick.x},0)`}>
                {tick.label.split("\n").map((line, j) => {
                  return (
                    <text
                      key={j}
                      x="0"
                      y={tick.offset || 0}
                      dy={10 * j}
                      textAnchor="middle"
                      dominantBaseline="text-before-edge"
                      fill="#888"
                      fontFamily="sans-serif"
                      fontSize={tick.fontSize || 10}
                      fontWeight="bold"
                    >
                      {line}
                    </text>
                  );
                })}
              </g>
            );
          })}
        </g>
        <g>
          {chart.yTicks.map(({ label, y }, i) => {
            return (
              <g key={i} transform={`translate(0,${y})`}>
                <line
                  x1="0"
                  y1="0"
                  x2={chart.contentWidth}
                  y2="0"
                  stroke="#ccc"
                  fill="none"
                />
                <text
                  x="-2"
                  y="0"
                  textAnchor="end"
                  dominantBaseline="central"
                  fill="#888"
                  fontFamily="sans-serif"
                  fontSize="10"
                  fontWeight="bold"
                >
                  {label}
                </text>
              </g>
            );
          })}
        </g>
        <g>
          {chart.lines.map(({ color, points }, i) => {
            return (
              <g key={i}>
                <path
                  d={line(points)}
                  fill="none"
                  stroke={color}
                  strokeWidth="2"
                  opacity="0.8"
                />
                {points.map((point, j) => {
                  return (
                    <circle
                      key={j}
                      cx={point.x}
                      cy={point.y}
                      r={chart.pointR || 0}
                      fill={color}
                      stroke="none"
                    />
                  );
                })}
              </g>
            );
          })}
        </g>
      </g>
    </svg>
  );
}