import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonMenuButton,
  IonContent,
  IonItem,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonRadio,
  IonRadioGroup,
  IonButton,
  IonCheckbox,
  IonList,
  IonAlert,
  IonImg,
} from "@ionic/react";
import { useState } from "react";
import "./Questionnaires.css";
import { useAuth0 } from "@auth0/auth0-react";
import { QuestionnairesKey } from "../../assets/Common";
import { postAnswer } from "../../services/api";
import { convertDate } from "../../assets/Common";
import tangImg from "../../images/tang.jpg";
import { useHistory } from "react-router";

//TODO:この内容もマスタ化するべき？
export const question1 = {
  1: "歯ぎしりをする",
  2: "口呼吸である",
  3: "口の中を触ることを嫌がる",
  4: "歯磨きへの拒否が強い",
  5: "指しゃぶりをやめられない",
  6: "該当なし",
};

export const question2 = {
  1: "むせやすい",
  2: "食べこぼしが多い",
  3: "よだれが多い",
  4: "食べ物を口に溜め込む",
  5: "指しゃぶりをやめられない",
  6: "食べ物を口に詰め込む",
  7: "食べ物を上あごに押し付ける",
  8: "口を開けたまま食べる",
  9: "チュチュ食べ",
  10: "丸のみ・噛むこと（咀嚼）をしない",
  11: "飲み込み（ごっくん）が不自然（飲み込む時に舌が前に出るなど）",
  12: "食べ物を口に入れる時に口を大きく開けすぎる（過開口）",
  13: "嘔気がある",
  14: "嘔吐しやすい",
  15: "一度飲み込んだ食べ物を口に戻す（反芻）",
  16: "誤嚥性肺炎になったことがある",
  17: "窒息したことがある",
  18: "該当なし",
};

const question3 = {
  1: "閉じることができない",
  2: "時々閉じることができる",
  3: "完璧でないがほぼ閉じることができる",
  4: "常時閉じることができる",
};

const question4 = {
  1: "常に前歯より前に出ている",
  2: "時々、前歯より前に出ている",
  3: "常に前歯より内側にある",
};

export const subQuestion3 = [
  {
    id: 1,
    question: question3,
    title: "安静時（食べ物が口の中に入っていない状態）",
    ans: -1,
  },
  {
    id: 2,
    question: question3,
    title:
      "食事をしている時（食べ物を取り込む時、口に入っている時、飲み込む時など）",
    ans: -1,
  },
];

export const subQuestion4 = [
  {
    id: 1,
    question: question4,
    title: "安静時（食べ物が口の中に入っていない状態）",
    ans: -1,
  },
  {
    id: 2,
    question: question4,
    title:
      "食事をしている時（食べ物を取り込む時、口に入っている時、飲み込む時など）",
    ans: -1,
  },
];

function RadioQuestion({
  question,
  title,
  index,
  subQuestion,
  setSubQuestion,
  title_index,
}) {
  return (
    <div>
      <IonItem lines="none">
        <IonLabel
          className="ion-text-wrap inherit_font_weight"
          style={{ fontSize: "1rem", margin: "4px" }}
        >
          {title_index}&ensp;
          {title}
        </IonLabel>
      </IonItem>
      <IonRadioGroup value={subQuestion[index]["ans"]}>
        {Object.entries(question).map(([rank, txt]) => {
          return (
            <IonItem lines="none" key={rank}>
              <IonLabel className="ion-text-wrap questionnaires_label">
                {txt}
              </IonLabel>
              <IonRadio
                style={{ marginRight: "8px" }}
                slot="start"
                value={rank}
                onClick={(e) => {
                  const copy = [...subQuestion];
                  copy[index]["ans"] = e.target.value;
                  setSubQuestion(copy);
                }}
              ></IonRadio>
            </IonItem>
          );
        })}
      </IonRadioGroup>
    </div>
  );
}

function RadioQuestionGroup({ title, subQuestion, setSubQuestion, index }) {
  return (
    <div className="question_group">
      <IonItem lines="none">
        <IonLabel
          className="title ion-text-wrap"
          style={{ fontSize: "1.1rem", margin: "0px" }}
        >
          {title}
        </IonLabel>
      </IonItem>
      {subQuestion.map((ques, idx) => {
        return (
          <RadioQuestion
            question={ques.question}
            title={ques.title}
            index={idx}
            subQuestion={subQuestion}
            setSubQuestion={setSubQuestion}
            key={idx}
            title_index={index + "." + (idx + 1)}
          />
        );
      })}
    </div>
  );
}

function CheckBoxQuestion({ question, title, ans, setAns }) {
  return (
    <div className="question_group">
      <IonItem lines="none">
        <IonLabel
          className="ion-text-wrap"
          style={{ fontSize: "1.1rem", margin: "0px" }}
        >
          {title}
        </IonLabel>
      </IonItem>
      <IonList lines="none">
        {Object.entries(question).map(([number, txt]) => {
          return (
            <IonItem lines="none" key={number}>
              <IonCheckbox
                slot="start"
                checked={ans.includes(number)}
                onClick={() => {
                  if (ans.includes(number)) {
                    setAns(ans.filter((a) => a !== number));
                  } else {
                    if (txt === "該当なし") {
                      setAns([number]);
                    } else {
                      //末尾が該当なしなので、末尾を削除
                      const copy = ans.filter((a) => {
                        return parseInt(a) !== Object.keys(question).length;
                      });
                      setAns([...copy, number]);
                    }
                  }
                }}
              ></IonCheckbox>
              <IonLabel className="ion-text-wrap questionnaires_label">
                {txt}
              </IonLabel>
            </IonItem>
          );
        })}
      </IonList>
    </div>
  );
}

function Content() {
  const [ans1, setAns1] = useState([]);
  const [ans2, setAns2] = useState([]);
  const [ques3, setQues3] = useState(JSON.parse(JSON.stringify(subQuestion3)));
  const [ques4, setQues4] = useState(JSON.parse(JSON.stringify(subQuestion4)));
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showSuccesDialog, setShowSuccesDialog] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const date = new Date();

  async function register() {
    const answerInfo = {
      date,
      ques1: ans1,
      ques2: ans2,
      ques3: extractAnswer(ques3),
      ques4: extractAnswer(ques4),
    };
    try {
      await postAnswer(
        QuestionnairesKey.EATING_AND_SWALLOWING,
        JSON.stringify(answerInfo),
        getAccessTokenSilently
      );
    } catch (e) {
      setShowErrorDialog(true);
      return;
    }
    setShowSuccesDialog(true);
    return;
  }

  function extractAnswer(questions) {
    return questions.map((q) => {
      return { id: q.id, answer: q.ans };
    });
  }

  function clearForm() {
    setAns1([]);
    setAns2([]);
    setQues3(JSON.parse(JSON.stringify(subQuestion3)));
    setQues4(JSON.parse(JSON.stringify(subQuestion4)));
  }

  function filledOutSubQuestion(ques) {
    return !ques.some((q) => q.ans === -1);
  }

  function allFilledOut() {
    if (!filledOutSubQuestion(ques3)) {
      return false;
    }
    if (!filledOutSubQuestion(ques4)) {
      return false;
    }
    return true;
  }

  return (
    <div>
      {/* TODO:通知的なアナウンス */}
      {/* <IonItem>
          <IonLabel>n月までにあとn人に出してもらいましょう</IonLabel>
        </IonItem> */}
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className="ion-text-center" color="secondary">
            食べる（摂食・嚥下）機能に関するアンケート
          </IonCardTitle>
        </IonCardHeader>
        <div className="explanation">
          今後のトレーニングによって、嚥下機能が改善できたかどうかを自己確認することを目的としています。
          あなたの食べる（食べ物を口から食べて胃まで運ぶこと）ことに関する状態について、いくつかの質問をいたします。よく読んで当てはまる項目に✔︎を付けてください。
        </div>
        <IonItem lines="none">
          <IonLabel>判定日</IonLabel>
          {convertDate(date)}
        </IonItem>

        <CheckBoxQuestion
          question={question1}
          title={"1.次のような症状や状態はありますか?（複数回答可）"}
          ans={ans1}
          setAns={setAns1}
        />

        <CheckBoxQuestion
          question={question2}
          title={
            "2.食べることに関して次のような症状はありますか？（複数回答可）"
          }
          ans={ans2}
          setAns={setAns2}
        />

        <RadioQuestionGroup
          title={"3.口唇を閉じることができますか?"}
          subQuestion={ques3}
          setSubQuestion={setQues3}
          index={"3"}
        />

        <RadioQuestionGroup
          title={
            "4.舌の先端は口の中におさまっていますか？（写真１：舌の先端が前歯より前に出ている状態）"
          }
          subQuestion={ques4}
          setSubQuestion={setQues4}
          index={"4"}
        />
        <div style={{ padding: "0 0 16px 20px" }}>
          <IonImg slot="start" src={tangImg} style={{ width: "150px" }} />
        </div>
      </IonCard>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "10px" }}
      >
        <IonButton
          onClick={register}
          disabled={!allFilledOut()}
          style={{
            width: "240px",
            color: "white",
          }}
        >
          送信
        </IonButton>
      </div>
      <IonAlert
        isOpen={showErrorDialog}
        onDidDismiss={() => setShowErrorDialog(false)}
        header="エラー"
        message={"エラーが発生しました。もう一度やり直してください。"}
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={showSuccesDialog}
        on
        onDidDismiss={() => {
          setShowSuccesDialog(false);
          clearForm();
          history.go(-1);
        }}
        header="送信完了"
        message={"アンケートの回答を受け付けました。"}
        buttons={["OK"]}
      />
    </div>
  );
}

export default function EatingAndSwallowing() {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="Header">
          <IonTitle>食べる機能に関するアンケート</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/questionnaires`} text="一覧" />
          </IonButtons>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Content />
      </IonContent>
    </IonPage>
  );
}
