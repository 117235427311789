import "./Task.css";
import folders from "../assets/task.json";

import { useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonToolbar,
  IonMenuButton,
  useIonViewWillEnter,
} from "@ionic/react";
import { useAuth0 } from "@auth0/auth0-react";
import { getTaskRecords } from "../services/api/record";
import { convertDate } from "../services/date";
import PageTitle from "../components/PageTitle";
import ProgressChart from "../components/chart/ProgressChart";
import Loading from "../components/Loading";

function Content({ records, folder }) {
  const progressData = useMemo(() => {
    if (records == null || folder == null) {
      return null;
    }

    const sortedRecords = records.reverse();

    return {
      labels: sortedRecords.map((record) =>
        convertDate(record.created).split(" ").join("\n")
      ),
      series: [
        {
          label: "じゅりちゃん",
          values: sortedRecords.map((record) => {
            if (record.juri_data === null || record.juri_data?.length === 0) {
              return 0;
            }
            const result = record.juri_data.find((result) => result.correct);
            if (!result) {
              return 0;
            }
            return Math.floor(result.score);
          }),
        },
        {
          label: "わたるくん",
          values: sortedRecords.map((record) => {
            if (record.wataru_data?.length === 0) {
              return 0;
            }
            const result = record.wataru_data.find((result) => result.correct);
            if (!result) {
              return 0;
            }
            return Math.floor(result.score);
          }),
        },
        {
          label: "あみちゃん",
          values: sortedRecords.map((record) => {
            if (
              record.ami_data === null ||
              Object.keys(record.ami_data).length === 0
            ) {
              return 0;
            }
            const result = record.ami_data.accuracy * 100;
            return result;
          }),
        },
        {
          label: "ごうくん",
          values: sortedRecords.map((record) => {
            if (
              record.gou_data === null ||
              Object.keys(record.gou_data).length === 0
            ) {
              return 0;
            }
            const result = record.gou_data.accuracy * 100;
            return result;
          }),
        },
        {
          label: "ハキハキ度",
          values: sortedRecords.map((record) => {
            if (record.crispness_data === -1) {
              return 0;
            }
            return record.crispness_data.score;
          }),
        },
      ],
    };
  }, [records, folder]);

  if (records == null || folder == null) {
    return <Loading />;
  }

  return (
    <>
      <PageTitle title={`「${folder.name}」の軌跡`} />
      <IonCard>
        <div style={{ width: "100%", height: "300px", padding: "10px" }}>
          {records.length === 0 ? (
            <div className="center-text">
              データがありません。練習しましょう
            </div>
          ) : (
            <ProgressChart data={progressData} />
          )}
        </div>
      </IonCard>
      <IonButton
        className="recording-button"
        expand="block"
        routerLink={`/task/${folder.id}/record`}
      >
        練習する!
      </IonButton>
      <hr />
      <IonList>
        {records?.reverse().map((item) => {
          return (
            <IonItem
              key={item.id}
              className="Past"
              detail={false}
              lines="none"
              routerLink={`/task/${folder.id}/record/${item.id}`}
            >
              <IonLabel className="ion-text-center">
                {convertDate(item.created)}
              </IonLabel>
            </IonItem>
          );
        })}
      </IonList>
    </>
  );
}

export default function Task() {
  const { folderId } = useParams();
  const [records, setRecords] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const folder = useMemo(() => {
    for (const d of folders) {
      if (d.id === Number(folderId)) {
        return d;
      }
    }
    return null;
  }, [folderId]);

  useIonViewWillEnter(() => {
    (async () => {
      const records = await getTaskRecords(folderId, getAccessTokenSilently);
      setRecords(records);
    })();
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="Header">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" text="ホーム" />
          </IonButtons>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Content records={records} folder={folder} />
      </IonContent>
    </IonPage>
  );
}
