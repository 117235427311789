import { requestGet } from "./request.js";

export async function getCalender(year, month, getAccessTokenSilently) {
  const response = await requestGet(
    `/api/calendar/${year}/${month}`,
    getAccessTokenSilently
  );
  return response.json();
}

export async function getCalenderStamp(year, month, getAccessTokenSilently) {
  const response = await requestGet(
    `/api/calendar/stamp/${year}/${month}`,
    getAccessTokenSilently
  );
  return response.json();
}
