import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonMenuButton,
  IonContent,
  IonItem,
  IonList,
  IonLabel,
} from "@ionic/react";
import "../components/questionnaires/Questionnaires.css";
import { QuestionnairesKey } from "../assets/Common";

function Content() {
  return (
    <div>
      <IonList>
        <IonItem
          lines="none"
          routerLink={`/questionnaires/status/${QuestionnairesKey.SPEECH_INTELLIGIBILITY}`}
          className="questionnaireList"
        >
          <IonLabel className="questionnaire" style={{ fontSize: "1rem" }}>
            会話明瞭度に関するアンケート
          </IonLabel>
        </IonItem>
        <IonItem
          lines="none"
          routerLink={`/questionnaires/status/${QuestionnairesKey.EATING_AND_SWALLOWING}`}
          className="questionnaireList"
        >
          <IonLabel
            className="ion-text-wrap questionnaire"
            style={{ fontSize: "1rem" }}
          >
            食べる（摂食・嚥下）機能に関するアンケート
          </IonLabel>
        </IonItem>
      </IonList>
    </div>
  );
}

export default function Questionnaires() {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="Header">
          <IonTitle>アンケート</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/`} text="ホーム" />
          </IonButtons>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Content />
      </IonContent>
    </IonPage>
  );
}
