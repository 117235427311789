import { useState } from "react";
import { IonButton, IonContent, IonPage, IonModal } from "@ionic/react";
import { useAuth0 } from "@auth0/auth0-react";
import "./Login.css";
import { isAgreed } from "./TermsOfService";
import Guide from "./Guide";

const Login = () => {
  const { loginWithRedirect, isLoading } = useAuth0();
  const [showModal, setShowModal] = useState(false);

  return (
    <IonPage>
      <IonContent>
        <div className="content">
          <div className="Title">
            パタカラ
            <br />
            プラス
            {isAgreed() ? (
              <IonButton
                onClick={loginWithRedirect}
                color="light"
                style={{ marginTop: "40px", width: "240px" }}
              >
                {isLoading ? "起動中" : "ログインする"}
              </IonButton>
            ) : (
              <IonButton
                onClick={() => {
                  setShowModal(true);
                }}
                color="light"
                style={{ marginTop: "40px", width: "240px" }}
              >
                始める
              </IonButton>
            )}
          </div>
          <div className="credit">
            このアプリは三菱財団の助成を
            <br />
            受けて開発されました
          </div>
        </div>
      </IonContent>

      <IonModal isOpen={showModal} backdropDismiss={false}>
        <Guide loginWithRedirect={loginWithRedirect} />
      </IonModal>
    </IonPage>
  );
};

export default Login;
