import "@ionic/react/css/core.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/text-alignment.css";
import "./App.css";

import { render } from "react-dom";
import { setupIonicReact } from "@ionic/react";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";

setupIonicReact({
  mode: "ios",
});

render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
  >
    <App />
  </Auth0Provider>,
  document.querySelector("#content")
);
