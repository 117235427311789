import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonMenuButton,
  IonContent,
  IonItem,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonRadio,
  IonRadioGroup,
  IonCheckbox,
  IonList,
  useIonViewWillEnter,
  IonImg,
} from "@ionic/react";
import { useState } from "react";
import "./Questionnaires.css";
import { useAuth0 } from "@auth0/auth0-react";
import { getAnswer } from "../../services/api";
import { convertDate } from "../../assets/Common";
import { useParams } from "react-router";
import Loading from "../Loading";
import "./QuestionnairesAns.css";
import {
  question1,
  question2,
  subQuestion3,
  subQuestion4,
} from "./EatingAndSwallowing";
import tangImg from "../../images/tang.jpg";

function RadioQuestion({ question, title, index, subQuestion }) {
  return (
    <div>
      <IonItem lines="none">
        <IonLabel
          className="ion-text-wrap inherit_font_weight"
          style={{ fontSize: "1rem", margin: "4px" }}
        >
          ○ {title}
        </IonLabel>
      </IonItem>
      <IonRadioGroup value={subQuestion[index]["ans"]}>
        {Object.entries(question).map(([rank, txt]) => {
          return (
            <IonItem lines="none" key={rank}>
              <IonLabel
                className="ion-text-wrap questionnaires_label"
                style={{ opacity: "1" }}
              >
                {txt}
              </IonLabel>
              <IonRadio
                style={{ marginRight: "8px" }}
                slot="start"
                value={rank}
                disabled={true}
                className="disabled"
              ></IonRadio>
            </IonItem>
          );
        })}
      </IonRadioGroup>
    </div>
  );
}

function RadioQuestionGroup({ title, subQuestion, setSubQuestion }) {
  return (
    <div className="question_group">
      <IonItem lines="none">
        <IonLabel
          className="title ion-text-wrap"
          style={{ fontSize: "1.1rem", margin: "0px" }}
        >
          {title}
        </IonLabel>
      </IonItem>
      {subQuestion.map((ques, index) => {
        return (
          <RadioQuestion
            question={ques.question}
            title={ques.title}
            index={index}
            subQuestion={subQuestion}
            setSubQuestion={setSubQuestion}
            key={index}
          />
        );
      })}
    </div>
  );
}

function CheckBoxQuestion({ question, title, ans }) {
  return (
    <div className="question_group">
      <IonItem lines="none">
        <IonLabel
          className="ion-text-wrap"
          style={{ fontSize: "1.1rem", margin: "0px" }}
        >
          {title}
        </IonLabel>
      </IonItem>
      <IonList lines="none">
        {Object.entries(question).map(([number, txt]) => {
          return (
            <IonItem lines="none" key={number}>
              <IonCheckbox
                slot="start"
                checked={ans.includes(number)}
                disabled={true}
                className="disabled"
              ></IonCheckbox>
              <IonLabel
                className="ion-text-wrap questionnaires_label"
                style={{ opacity: "1" }}
              >
                {txt}
              </IonLabel>
            </IonItem>
          );
        })}
      </IonList>
    </div>
  );
}

function Content({ data }) {
  const ques3 = JSON.parse(JSON.stringify(subQuestion3));
  const ques4 = JSON.parse(JSON.stringify(subQuestion4));

  ques3[0]["ans"] = data.ques3[0]["answer"];
  ques3[1]["ans"] = data.ques3[1]["answer"];
  ques4[0]["ans"] = data.ques4[0]["answer"];
  ques4[1]["ans"] = data.ques4[1]["answer"];

  return (
    <div>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className="ion-text-center" color="secondary">
            食べる（摂食・嚥下）機能に関するアンケート
          </IonCardTitle>
        </IonCardHeader>
        <div className="explanation">
          今後のトレーニングによって、嚥下機能が改善できたかどうかを自己確認することを目的としています。
          あなたの食べる（食べ物を口から食べて胃まで運ぶこと）ことに関する状態について、いくつかの質問をいたします。よく読んで当てはまる項目に✔︎を付けてください。
        </div>
        <IonItem lines="none">
          <IonLabel>判定日</IonLabel>
          {convertDate(new Date(data.date))}
        </IonItem>

        <CheckBoxQuestion
          question={question1}
          title={"1.次のような症状や状態はありますか?（複数回答可）"}
          ans={data.ques1}
        />

        <CheckBoxQuestion
          question={question2}
          title={
            "2.食べることに関して次のような症状はありますか？（複数回答可）"
          }
          ans={data.ques2}
        />

        <RadioQuestionGroup
          title={"3.口唇を閉じることができますか?"}
          subQuestion={ques3}
        />

        <RadioQuestionGroup
          title={
            "4.舌の先端は口の中におさまっていますか？（写真１：舌の先端が前歯より前に出ている状態）"
          }
          subQuestion={ques4}
        />
        <div style={{ padding: "0 0 16px 20px" }}>
          <IonImg slot="start" src={tangImg} style={{ width: "150px" }} />
        </div>
      </IonCard>
    </div>
  );
}

//TODO:コンポーネントの共通化?
export default function EatingAndSwallowingAns() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useIonViewWillEnter(() => {
    const getAns = async () => {
      const ans = await getAnswer(id, getAccessTokenSilently);
      setData(JSON.parse(ans["answer"]));
    };
    getAns();
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="Header">
          <IonTitle>回答</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/questionnaires`} text="一覧" />
          </IonButtons>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data === null ? <Loading /> : <Content data={data} />}
      </IonContent>
    </IonPage>
  );
}
