import {
  IonSlide,
  IonItem,
  IonCheckbox,
  IonLabel,
  IonInput,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonButton,
} from "@ionic/react";
import { useState } from "react";

function ConsentForm() {
  const [name, setName] = useState("");
  const [age, setAge] = useState(null);
  const [guardianName, setGuardianName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [agreed, setAgreed] = useState(false);

  function filledOut() {
    if (name === "") {
      return false;
    }
    if (age === null) {
      return false;
    }
    if (guardianName === "") {
      return false;
    }
    if (email === "") {
      return false;
    }
    if (tel === "") {
      return false;
    }
    return true;
  }

  return (
    <IonSlide>
      <div>
        <IonCard>
          <div>
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">同意書</IonCardTitle>
            </IonCardHeader>

            <div className="consent_form">
              <IonItem>
                <IonLabel> ご利用者</IonLabel>
                <IonInput
                  placeholder="お名前"
                  value={name}
                  onIonChange={(e) => setName(e.target.value)}
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel> 年齢</IonLabel>
                <IonInput
                  placeholder="20"
                  type="number"
                  value={age}
                  onIonChange={(e) => setAge(e.target.value)}
                ></IonInput>
              </IonItem>

              <IonItem lines="none">
                <IonLabel> ご家族・代諾者のお名前</IonLabel>
              </IonItem>
              <IonItem>
                <IonInput
                  placeholder="お名前"
                  value={guardianName}
                  onIonChange={(e) => setGuardianName(e.target.value)}
                ></IonInput>
              </IonItem>

              <div>
                <IonItem lines="none">
                  <IonLabel>メールアドレス</IonLabel>
                </IonItem>
                <IonItem>
                  <IonInput
                    type="email"
                    placeholder="email@domain.com"
                    value={email}
                    onIonChange={(e) => setEmail(e.target.value)}
                  ></IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel>電話番号</IonLabel>
                  <IonInput
                    type="tel"
                    placeholder="888-888-8888"
                    value={tel}
                    onIonChange={(e) => setTel(e.target.value)}
                  ></IonInput>
                </IonItem>
              </div>
            </div>

            <div className="terms-of-servic-center">
              <IonItem lines="none">
                <div>
                  <IonCheckbox
                    checked={agreed}
                    onIonChange={(e) => setAgreed(e.detail.checked)}
                    disabled={!filledOut()}
                  />
                </div>
                <div className={`consent_text ${!filledOut() && "disable"}`}>
                  内容を理解し、本システムを利用したいため、本検討に参加することに同意します。
                </div>
              </IonItem>
            </div>
          </div>
        </IonCard>

        <div
          style={{ display: "flex", justifyContent: "center", margin: "10px" }}
        >
          <IonButton
            onClick={() => {
              // setAgreedToLocalStrage();
              // TODO:そのままAuthのログインがいいのか、一回ホームに戻ってログインするボタンを押させてからAuthのログインに行くか
              // loginWithRedirect();
            }}
            color="primary"
            style={{
              width: "240px",
            }}
            disabled={!(filledOut() && agreed)}
          >
            次へ
          </IonButton>
        </div>
      </div>
    </IonSlide>
  );
}

export default ConsentForm;
