import { requestGet, requestPost } from "./request.js";

export async function getRecords(getAccessTokenSilently) {
  const response = await requestGet(
    `/api/records/list`,
    getAccessTokenSilently
  );
  return response.json();
}

export async function getRecord(id, getAccessTokenSilently) {
  const response = await requestGet(
    `/api/records/detail/${id}`,
    getAccessTokenSilently
  );
  return response.json();
}

export async function getTaskRecords(id, getAccessTokenSilently) {
  const response = await requestGet(
    `/api/records/task/${id}`,
    getAccessTokenSilently
  );
  return response.json();
}

export async function postRecord(id, body, getAccessTokenSilently) {
  return requestPost(`/api/records/task/${id}`, body, getAccessTokenSilently)
    .then((response) => {
      return response.json();
    })
    .catch((e) => {
      throw new Error("データの取得に失敗しました。");
    });
}

export async function getRunningDays(getAccessTokenSilently) {
  const response = await requestGet(
    `/api/running_days`,
    getAccessTokenSilently
  );
  return response.json();
}
