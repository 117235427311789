import { useMemo, useRef } from "react";
import * as d3 from "d3";
import { useResize } from "./useResize";
import LineChart from "./LineChart";

export default function CrispnessChart({ data }) {
  const wrapperRef = useRef();
  const size = useResize(wrapperRef);

  const chart = useMemo(() => {
    const { width: displayWidth, height: displayHeight } = size;
    const margin = {
      top: 10,
      right: 15,
      bottom: 20,
      left: 30,
    };
    const contentWidth = Math.max(0, displayWidth - margin.left - margin.right);
    const contentHeight = Math.max(
      0,
      displayHeight - margin.top - margin.bottom
    );

    const xScale = d3
      .scaleLinear()
      .domain([0, data.length - 1])
      .range([0, contentWidth])
      .nice();
    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data)])
      .range([contentHeight, 0])
      .nice();

    return {
      width: displayWidth,
      height: displayHeight,
      contentWidth,
      contentHeight,
      margin,
      lines: [
        {
          color: "#ff9292",
          points: data.map((d, i) => ({ x: xScale(i), y: yScale(d) })),
        },
      ],
      xTicks: xScale
        .ticks(xScale.domain()[1] / 50)
        .map((i) => ({ label: `${i}`, x: xScale(i), offset: 2 })),
      yTicks: yScale
        .ticks(yScale.domain()[1] / 0.5)
        .map((d) => ({ label: `${d}`, y: yScale(d) })),
    };
  }, [data, size]);

  return (
    <div ref={wrapperRef} className="chart">
      <LineChart chart={chart} />
    </div>
  );
}
