import { requestGet, requestPost, requestPut } from "./request.js";

export async function getUserInfo(getAccessTokenSilently) {
  const response = await requestGet(`/api/user`, getAccessTokenSilently);
  return response.json();
}

export async function registerUserInfo(body, getAccessTokenSilently) {
  return requestPost(`/api/user/register`, body, getAccessTokenSilently)
    .then((response) => {
      return response.json();
    })
    .catch((e) => {
      throw e;
    });
}

export async function putRegisterUserInfo(body, getAccessTokenSilently) {
  return requestPut(`/api/user/register/update`, body, getAccessTokenSilently)
    .then((response) => {
      return response.json();
    })
    .catch((e) => {
      throw e;
    });
}
