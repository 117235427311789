import { IonButton, useIonPicker } from "@ionic/react";
import { useEffect, useState } from "react";
import "./DateOfBirthPicker.css";

function isUruYear(year) {
  if (year % 100 === 0 && year % 400 !== 0) {
    return false;
  } else if (year % 4 === 0) {
    return true;
  }
  return false;
}

function getMonthDays(year, month) {
  switch (month) {
    case 1 || 3 || 5 || 7 || 8 || 10 || 12:
      return 31;
    case 4 || 6 || 9 || 11:
      return 30;
    case 2:
      if (isUruYear(year)) {
        return 29;
      }
      return 28;
    default:
      return 31;
  }
}

export default function DateOfBirthPicker({ birthDay, setBirthDay }) {
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [present] = useIonPicker();

  useEffect(() => {
    (async () => {
      if (birthDay) {
        const _birthDay = new Date(birthDay);
        setYear(_birthDay.getFullYear());
        setMonth(_birthDay.getMonth() + 1);
        setDateOfBirth(_birthDay.getDate());
      }
    })();
  }, [birthDay]);

  function integrateDate(_year, _month, _date) {
    if (_year === null || _month === null || _date === null) {
      setBirthDay(null);
      return;
    }
    const _birthdDay = new Date(_year, _month - 1, _date);
    setBirthDay(_birthdDay);
  }

  const openPicker = async () => {
    present({
      columns: [
        {
          name: "year",
          selectedIndex: year === null ? 23 : 2023 - year,
          options: [...Array(124)]
            .map((_, i) => {
              return { text: 1900 + i, value: 1900 + i };
            })
            .sort((a, b) => b.value - a.value),
        },
      ],
      buttons: [
        {
          text: "キャンセル",
          role: "cancel",
        },
        {
          text: "確定",
          handler: (value) => {
            setYear(value.year.value);
            integrateDate(value.year.value, month, dateOfBirth);
          },
        },
      ],
    });
  };

  const openPickerMonth = async () => {
    present({
      columns: [
        {
          name: "month",
          selectedIndex: month - 1,
          options: [...Array(12)].map((_, i) => {
            return { text: i + 1, value: i + 1 };
          }),
        },
      ],
      buttons: [
        {
          text: "キャンセル",
          role: "cancel",
        },
        {
          text: "確定",
          handler: (value) => {
            setMonth(value.month.value);
            integrateDate(year, value.month.value, dateOfBirth);
          },
        },
      ],
    });
  };

  const openPickerDate = async () => {
    present({
      columns: [
        {
          name: "date",
          selectedIndex: dateOfBirth - 1,
          options: [...Array(getMonthDays(year, month))].map((_, i) => {
            return { text: i + 1, value: i + 1 };
          }),
        },
      ],
      buttons: [
        {
          text: "キャンセル",
          role: "cancel",
        },
        {
          text: "確定",
          handler: (value) => {
            setDateOfBirth(value.date.value);
            integrateDate(year, month, value.date.value);
          },
        },
      ],
    });
  };

  return (
    <div className="birthday">
      <div className="year">
        <IonButton className="year_button" onClick={openPicker} size="small">
          {year}
        </IonButton>
        年
      </div>
      <div className="month">
        <IonButton
          className="month_button"
          onClick={openPickerMonth}
          size="small"
        >
          {month}
        </IonButton>
        月
      </div>
      <div className="date">
        <IonButton
          className="date_button"
          onClick={openPickerDate}
          size="small"
        >
          {dateOfBirth}
        </IonButton>
        日
      </div>
    </div>
  );
}
