import {
  IonCard,
  IonSlide,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonCheckbox,
  IonButton,
} from "@ionic/react";
import "./Guide.css";
import { useState } from "react";

export function isAgreed() {
  if ("agreed" in localStorage) {
    return true;
  } else {
    return false;
  }
}

function RequestForUse1({ loginWithRedirect }) {
  const [agreed, setAgreed] = useState(false);

  function setAgreedToLocalStrage() {
    if (!isAgreed()) {
      localStorage.setItem("agreed", "true");
    }
  }
  return (
    <IonSlide>
      <div className="request_card_position">
        <IonCard className="consent_card" style={{ marginBottom: "12px" }}>
          <IonCardHeader>
            <IonCardTitle className="ion-text-center">利用規約</IonCardTitle>
          </IonCardHeader>
          <div className="consent_wrapper">
            <div className="consent_form">
              <div>
                <div className="text_left">
                  本システムは無料でご使用いただけますが、
                  <span className="emphasis_text">
                    簡単な属性（ダウン症かそれ以外か、性別、年齢のみ）
                  </span>
                  のご記入と{" "}
                  <span className="emphasis_text">
                    本検討が全体的な統計的評価を行わせていただく{" "}
                  </span>
                  と言うことについての同意をいただいてからのご使用になります。
                </div>
                <ul className="itemize">
                  <li>個人名などプライバシーの保護は保たれます。</li>
                  <li>
                    ご同意後、撤回もできます（撤回した後はこれまでのデータは消去されます）。
                  </li>
                </ul>
              </div>

              <div>
                <div className="text_left">
                  このシステムがより使い勝手が良いものにするために、是非、ご了承の上、お使いいただき、使用される方々に何らかの役に立つことを願っています。
                </div>
                <div>
                  <div> パタカラプラス評価グループ一覧</div>
                  <ul className="itemize">
                    <li>
                      近藤達郎：みさかえの園総合発達医療福祉センターむつみの家
                    </li>
                    <li>三嶋博之：長崎大学医学部原研遺伝</li>
                    <li>北原鉄朗：日本大学文理学部</li>
                    <li>尾上洋介：日本大学文理学部</li>
                    <li>小松知子：神奈川歯科大学</li>
                    <li>李 昌一：神奈川歯科大学</li>
                    <li>小林健史：北海道医療大学</li>
                    <li>
                      川口靖子・富永眞理子：バンビの会（染色体障害児・者を支える会）
                    </li>
                  </ul>
                </div>
                <div>
                  研究助成：長崎県医師会医学研究助成、三菱財団社会福祉事業・研究助成、日本障害者歯科科学会宿題委託研究、長崎県信用組合協会（バンビの会）
                </div>
                <div>
                  ご連絡先：
                  <a
                    href="mailto:banbinokaisen@gmail.com"
                    style={{ fontWeight: "bold" }}
                  >
                    バンビの会(mail)
                  </a>
                  まで
                </div>
              </div>
            </div>
          </div>
          <div className="terms-of-servic-center">
            <IonItem lines="none">
              <div>
                <IonCheckbox
                  checked={agreed}
                  onIonChange={(e) => setAgreed(e.detail.checked)}
                />
              </div>
              <div className={`consent_text `}>
                内容を理解し、本システムを利用したいため、本検討に参加することに同意します。
              </div>
            </IonItem>
          </div>
        </IonCard>
        <IonButton
          onClick={() => {
            setAgreedToLocalStrage();
            loginWithRedirect();
          }}
          color="primary"
          style={{
            width: "240px",
            marginBottom: "36px",
          }}
          disabled={!agreed}
        >
          次へ
        </IonButton>
      </div>
    </IonSlide>
  );
}

export default RequestForUse1;
