import {
  IonCard,
  IonSlide,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import { arrowDownOutline } from "ionicons/icons";

import "./Guide.css";

function HowToUse() {
  return (
    <IonSlide>
      <IonCard className="card_height">
        <IonCardHeader>
          <IonCardTitle className="ion-text-center">使用方法</IonCardTitle>
        </IonCardHeader>
        <div className="text_left text_wrapper">
          <div>
            中には、
            <span className="emphasis_text">
              会話明瞭度のアンケート調査、嚥下（飲み込み方）のアンケート調査、発生チェック
            </span>
            の3つのコンテンツが含まれています。
            これらは保存されていきますので、一定期間ごとにチェックをしていただくとその変化を確認することができます。
          </div>
          <div>
            <ul className="itemize">
              <li>
                会話のアンケート調査（3~6ヶ月毎に家族と家族以外でのチェックが望ましい）
              </li>
              <li>嚥下のアンケート調査（3~6ヶ月毎のチェックが望ましい）</li>
              <li>
                発音のチェック（わたるくん、あみちゃん、ごうくん、ハキハキ度、発音回数）（毎日でも可）
              </li>
            </ul>
            <div className="arrow_icon">
              <IonIcon size="large" icon={arrowDownOutline} />
            </div>
            <ul className="itemize">
              <li>
                保存できているので、パタカラプラス・コンテンツ使用など何らかのトレーニングの効果を利用本人・家族が把握でき、モチベーションの維持につながる。
              </li>
              <li>
                どうしても苦手で日常生活に支障があるようなら、リハビリなどの専門家に相談し、更なる改善を目指す。
              </li>
            </ul>
          </div>
          <div>
            このシステムをより良いものにするために、
            <span className="emphasis_text">データを集積させていただく</span>
            ことにしています。
          </div>
        </div>
      </IonCard>
    </IonSlide>
  );
}

export default HowToUse;
