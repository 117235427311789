import * as d3 from "d3";
import { useState, useRef, useEffect, useMemo } from "react";
import "./../../App.css";
import { DISPLAY_COUNT } from "../../assets/Common.js";

export default function ScrollLineChart({ chart }) {
  const line = d3
    .line()
    .x(({ x }) => x)
    .y(({ y }) => y);

  const svgRef = useRef();
  const [scrollOffset, setScrollOffset] = useState(5);
  const [grabbing, setGrabbing] = useState(false);

  useEffect(() => {
    const dayCount = chart.lines[0].points.length;
    const drag = d3
      .drag()
      .on("start", () => {
        setGrabbing(true);
      })
      .on("drag", (event) => {
        if (event.dx > 0) {
          setScrollOffset((scrollOffset) =>
            Math.min(DISPLAY_COUNT, scrollOffset + 0.1)
          );
        } else if (event.dx < 0) {
          setScrollOffset((scrollOffset) =>
            Math.max(DISPLAY_COUNT - dayCount + 1, scrollOffset - 0.1)
          );
        }
      })
      .on("end", () => {
        setGrabbing(false);
      });
    d3.select(svgRef.current).call(drag);
    setScrollOffset(DISPLAY_COUNT - dayCount + 5);
  }, [chart]);

  const scroll = useMemo(() => {
    const dayCount = chart.lines[0].points.length;
    const lineWidth = DISPLAY_COUNT < dayCount ? chart.contentWidth : 0;
    const chartXScale = d3
      .scaleLinear()
      .domain([0, DISPLAY_COUNT])
      .range([-lineWidth, 0]);

    return {
      lineWidth: lineWidth,
      chartXScale: chartXScale,
      dayCount: dayCount,
    };
  }, [chart]);

  return (
    <svg
      viewBox={`0 0 ${chart.width} ${chart.height}`}
      ref={svgRef}
      className={grabbing && DISPLAY_COUNT < scroll.dayCount ? "grabbing" : ""}
    >
      <g transform={`translate(${chart.margin.left},${chart.margin.top})`}>
        <g>
          {chart.lines.map(({ color, label }, i) => {
            return (
              <g key={i}>
                <circle
                  cx={i * 65}
                  cy={-chart.margin.top / 2}
                  r={chart.pointR || 0}
                  fill={color}
                  stroke="none"
                />
                <text
                  x={chart.pointR * 2 + i * 65 - 3}
                  y={-chart.margin.top / 2}
                  textAnchor="start"
                  dominantBaseline="middle"
                  fill="#888"
                  fontFamily="sans-serif"
                  fontSize={9}
                  fontWeight="bold"
                >
                  {label}
                </text>
              </g>
            );
          })}
        </g>
        <g transform={`translate(0,${chart.contentHeight})`}>
          {chart.xTicks.map((tick, i) => {
            return (
              <g
                key={i}
                transform={`translate(${
                  tick.x + scroll.chartXScale(scrollOffset)
                },0)`}
              >
                {tick.label.split("\n").map((line, j) => {
                  return (
                    <text
                      key={j}
                      x="0"
                      y={tick.offset || 0}
                      dy={10 * j}
                      textAnchor="middle"
                      dominantBaseline="text-before-edge"
                      fill="#888"
                      fontFamily="sans-serif"
                      fontSize={tick.fontSize || 10}
                      fontWeight="bold"
                    >
                      {line}
                    </text>
                  );
                })}
              </g>
            );
          })}
        </g>
        <g>
          {chart.lines.map(({ color, points }, i) => {
            return (
              <g
                key={i}
                transform={`translate(${scroll.chartXScale(scrollOffset)},0)`}
              >
                <path
                  d={line(points)}
                  fill="none"
                  stroke={color}
                  strokeWidth="4"
                  opacity="0.8"
                />
                {points.map((point, j) => {
                  return (
                    <circle
                      key={j}
                      cx={point.x}
                      cy={point.y}
                      r={chart.pointR || 0}
                      fill={color}
                      stroke="none"
                    />
                  );
                })}
              </g>
            );
          })}
        </g>
        <g>
          {chart.yTicks.map(({ label, y }, i) => {
            return (
              <g key={i} transform={`translate(0,${y})`}>
                <line
                  x1="0"
                  y1="0"
                  x2={chart.contentWidth}
                  y2="0"
                  stroke="#ccc"
                  fill="none"
                />
                <text
                  x={chart.contentWidth + 2}
                  y="0"
                  textAnchor="start"
                  dominantBaseline="central"
                  fill="#888"
                  fontFamily="sans-serif"
                  fontSize="10"
                  fontWeight="bold"
                >
                  {label}
                </text>
              </g>
            );
          })}
        </g>
      </g>
    </svg>
  );
}
